<template>
  <div class="content">
    <el-container>
      <el-aside>
        <el-col>
          <img class="col_img" :src="userIcon" alt="">
          <el-menu
          default-active="2">
            <!-- <el-menu-item index="1" @click="menuItem">
              <el-icon><coin /></el-icon>
              <span>工作台</span>
            </el-menu-item> -->
            <el-menu-item index="2" @click="menuItem">
              <el-icon><edit /></el-icon>
              <span>{{$t('m.merchantworkbench.Release_management')}}</span>
            </el-menu-item>
            <el-menu-item index="4" @click="menuItem">
              <el-icon><List /></el-icon>
              <span>{{$t('m.merchantworkbench.order_management')}}</span>
            </el-menu-item>
            <el-menu-item index="3" @click="menuItem">
              <el-icon><document /></el-icon>
              <span>{{$t('m.merchantworkbench.Merchandise_management')}}</span>
            </el-menu-item>
            <el-menu-item index="5" @click="menuItem">
              <el-icon><setting /></el-icon>
              <span>{{$t('m.merchantworkbench.Audit_management')}}</span>
            </el-menu-item>
            <el-menu-item index="6" @click="menuItem">
              <el-icon><Van /></el-icon>
              <span>{{'仓储管理'}}</span>
            </el-menu-item>
            <el-menu-item index="7" @click="menuItem">
              <el-icon><Histogram /></el-icon>
              <span>{{'竞拍管理'}}</span>
            </el-menu-item>
            <!-- <el-menu-item index="5" @click="menuItem">
              <el-icon><setting /></el-icon>
              <span>数据</span>
            </el-menu-item>
            <el-menu-item index="6" @click="menuItem">
              <el-icon><user /></el-icon>
              <span>权限</span>
            </el-menu-item> -->
          </el-menu>
        </el-col>
      </el-aside>
      <el-container>
        <el-header>
          <el-button @click="toindex">
            <el-icon ><ArrowLeftBold /></el-icon>{{$t('m.merchantworkbench.back_homepage')}}
          </el-button>
          <!-- <el-button class="user_name">
            {{'账户名称'}}
          </el-button> -->
        </el-header>
        <el-main v-if="activeName!=='OrderManagementdetail'">
          <!-- <keep-alive> -->
            <component :is="activeName">
            </component>
          <!-- </keep-alive> -->
          <!-- <router-view/> -->
          <!-- <PostTrade /> -->
        </el-main>
        <el-main v-else>
          <OrderManagementdetail :datadetail="datadetail"/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// 发布商品
import PostTradeMain from './posttrade/PostTradeMain.vue'
// 工作台
import WorkBenchMain from './workbench/WorkBenchMain.vue'
// 商品管理
import commodityMain from './commodity/commodityMain.vue'
// 审核管理
import AuditManagement from './Audit/AuditManagement.vue'
// 订单信息
import OrderManagement from './Order/OrderManagement.vue'
import OrderManagementdetail from './Order/OrderDetail.vue'
// 仓储管理
import warehouse from './warehouse/index.vue'
// 竞拍管理
import auctionMainVue from './auction/auctionMain.vue'

import emitter from '../../tools/bus'
import { getStore } from '../../tools/storage'

export default {
  name: 'MerchantWorkbench',
  data () {
    return {
      activeName: 'PostTradeMain',
      userIcon: '',
      datadetail: '' // 订单详情
    }
  },
  methods: {
    menuItem (e) {
      // console.log(typeof e.index)
      // this.$store.state.merchantNumber = e.index
      switch (e.index) {
        // case '1':
        //   this.activeName = 'WorkBenchMain'
        //   break
        case '2':
          this.activeName = 'PostTradeMain'
          break
        case '3':
          this.activeName = 'commodityMain'
          break
        case '4':
          this.activeName = 'OrderManagement'
          break
        case '5':
          this.activeName = 'AuditManagement'
          break
        case '6':
          this.activeName = 'warehouse'
          break
        case '7':
          this.activeName = 'auctionMainVue'
          break
        default:
          break
      }
    },
    toindex () {
      this.$router.push('/')
      setTimeout(() => {
        window.location.reload()
      }, 300)
    }
  },
  mounted () {
    this.userIcon = JSON.parse(getStore('User')).avatar
    // console.log()
    emitter.on('merchantorderdetail', (datadetail) => {
      // console.log(data)
      this.datadetail = datadetail
      this.activeName = 'OrderManagementdetail'
    })
    // console.log(this.$store.state.merchantNumber)
  },
  components: { PostTradeMain, WorkBenchMain, OrderManagement, AuditManagement, OrderManagementdetail, commodityMain, warehouse, auctionMainVue },
  setup () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  // padding: 0 20px;
  height: 100vh;
  .el-container{
    background: #F2EFED;
    // height: 100%;
    height: inherit;
    .el-aside{
      min-width: 200px;
      max-width: 250px;
      box-sizing: border-box;
      margin-right: 10px;
      // display: flex;
      .el-col{
        width: 100%;
        max-width: 100%;
        height: 600px;
        background: #ffffff;
        .col_img{
          display: block;
          margin: 30px auto;
          border-radius: 5px;
          width: 100px;
          // height: 100px;
        }
        .el-menu{
          width: 100%;
        }
      }
    }
    .el-container{
      .el-main{
        flex: 1;
        margin-top: 10px;
        background: #ffffff;
        // min-height: 900px;
      }
    }
    .is-vertical{
      .el-header{
        height: 70px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // .el-button{

        // }
        .user_name{
          // height: 40px;
          width: 100px;
          height: 40px;
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          border-radius: 4px;
          color: #ffffff;
        }
      }
      .el-main{
        background: #ffffff;
      }
    }
  }
}
</style>
