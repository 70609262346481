<template>
<!-- 审核管理 -->
<div class='cont'>
  <div class="title">
    {{$t('m.merchantworkbench.Audit_management')}}
  </div>
  <el-card class="filter-container" shadow="never">
    <template #header>
      <span>筛选</span>
    </template>
    <div class="selectname">
      <el-radio-group v-model="radio" @change="currentPage=1;initData(1,radio)" size="small">
        <el-radio-button label="全部"></el-radio-button>
        <el-radio-button label="审核通过"></el-radio-button>
        <el-radio-button label="未审核"></el-radio-button>
        <el-radio-button label="未通过"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="selectname">
      <el-select v-model="fenleiValue" filterable clearable placeholder="分类选择" size="small"
      @change="fenleichange">
        <el-option
          v-for="item in fenleioptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>
  </el-card>

  <div class="order_body">
    <tr>
      <th v-for="i in alltitle" :key="i">
        {{i}}
      </th>
    </tr>
    <tr>
      <div class="content">
        <ul class="order_body_ul"
        v-for="(i,index) in dataList" :key="index">
          <li class="order_body_li body_num1">
            <div class="namebody">
              <p>{{i.name}}</p>
            </div>
          </li>
          <li class="order_body_li body_num2">
            <span class="li2_span_kg">{{
              i.productSn
              }}</span>
              <!-- <p>规格1</p> -->
          </li>
          <li class="order_body_li body_num3">
            <p class="name_phone_p">{{
              i.price
              }}</p>
          </li>
          <li class="order_body_li body_num3">
            <p class="name_phone_p">{{i.createTime}}</p>
          </li>
          <li class="order_body_li body_num4">
            <span class="money_span">
              {{i.verifyStatus==0?'未审核':i.verifyStatus==1?'审核通过':i.verifyStatus==2?'未通过':''}}
            </span>
          </li>
          <li class="order_body_li body_num5">
            <span>{{i.stock+i.unit}}</span>
          </li>
          <li class="order_body_li body_num5">
            <span>{{
              i.previewStatus==0?'现货商品':
              i.previewStatus==1?'预售商品':
              i.previewStatus==2?'现货样品':
              i.previewStatus==3?'易货商品':''
              }}</span>
            <el-tag v-if="i.previewStatus==2" size="small" effect="plain" type="danger">样品</el-tag>
          </li>
          <li class="order_body_li body_num6">
            <span v-if="i.verifyStatus==2"
              @click.stop="removeOrder(i, index)"
              class="order_detailcss">
              {{'删除'}}
            </span>

            <!-- <span class="order_detailcss"
            @click="lookDetails(i)">详情</span> -->
            <span class="order_detailcss" v-if="i.previewStatus!=3"
            @click="lookDetails(i)">{{$t('m.merchantworkbench.Republish')}}</span>
          </li>
        </ul>
        <div class="nodata" v-if="dataList.length == 0">
          <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
        </div>
      </div>
    </tr>
  </div>
   <!-- 底部分页 -->
  <div class="showfooter" v-if="totalnum<pagesize?false:true">
    <el-pagination
      v-model:currentPage="currentPage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalnum"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</div>
<el-dialog v-model="lookshow" :title="$t('m.merchantworkbench.Republish')"
:close-on-click-modal="false"
:close-on-press-escape="false"
:before-close="closeDilog"
  width="70%">
  <div v-if="lookshow">
    <TradeFrom :lookDetails="lookData"/>
  </div>
</el-dialog>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../../api/http'
import { ElLoading, ElMessage } from 'element-plus'
import TradeFrom from '../posttrade/TradeFrom.vue'
import emitter from '../../../tools/bus'
import moment from 'moment'

// 卖家审核列表
const verifyList = (data) => {
  return http.get('mall-merchant/product/verifyList', data)
}
// 删除商品
const upShelfProduct = (data) => {
  return http.post('/mall-merchant/product/update/deleteStatus', data)
}
// 重新发布商品
const updateInfo = (id) => {
  return http.get(`/mall-merchant/product/updateInfo/${id}`)
}
// 查分类
const productAttribute = (data) => {
  return http.get('/mall-portal/productCategory/list/withChildren')
}
export default {
  name: 'AuditManagement',
  data () {
    return {
      // alltitle: ['商品名称', '商品编号', '价格', '发布时间', '状态', '库存', '商品类别', '操作'],
      alltitle: [
        this.$t('m.index.product_name'),
        this.$t('m.merchantworkbench.Product_ID'),
        this.$t('m.auction_englishauction.price'),
        this.$t('m.merchantworkbench.release_time'),
        this.$t('m.personalcenter_myorder.state'),
        this.$t('m.reservation_varietiy_details.guigeStock'),
        this.$t('m.merchantworkbench.merchandise_categories'),
        this.$t('m.auction.operate')
      ],
      dataList: [],
      fenleioptions: [], // 分类
      fenleiValue: '', // 选中的分类id

      // 上下架商品
      radio: '全部',

      // 重新发布
      lookshow: false, // dialog展示
      lookData: '', // 重新发布数据

      // 底部分页
      totalnum: ref(100), // 存放总数据个数
      pagesize: ref(15), // 存放一页显示个数
      currentPage: ref(1) // 默认展示第几页
    }
  },
  components: { TradeFrom },
  methods: {
    async initData (num, status) { // 初始化查询商品
      const loading = ElLoading.service({
        lock: true,
        text: '查询中...'
      })
      let sts = ''
      switch (status) {
        case '全部':
          sts = ''
          break
        case '审核通过':
          sts = 1
          break
        case '未审核':
          sts = 0
          break
        case '未通过':
          sts = 2
          break
        default:
          break
      }
      const data = {
        pageSize: this.pagesize,
        pageNum: num,
        verifyStatus: sts,
        productCategoryId: this.fenleiValue
      }
      console.log(data)
      const res = await verifyList(data)
      if (res.data.code === 200) {
        console.log(res)
        this.totalnum = res.data.data.total // 总数据数
        this.dataList = res.data.data.list // 要展示的数据
        console.log(this.dataList)
        loading.close()
      }
      loading.close()
    },
    // 初始化分类列表
    async getProductAttribute () {
      const res = await productAttribute()
      console.log(res)
      if (res.data.code === 200) {
        res.data.data[0].children.forEach(item => {
          this.fenleioptions.push({
            name: item.name,
            id: item.id
          })
        })
      }
    },
    handleCurrentChange (val) { // 分页
      console.log(`current page: ${val}`)
      this.initData(val)
    },
    removeOrder (item, index, status) { // 删除按钮
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = {
            ids: item.id,
            deleteStatus: 1
          }
          upShelfProduct(data).then(res => {
            // console.log(res)
            this.dataList.splice(index, 1)
          })
        })
        .catch(() => {
        })
    },
    async lookDetails (item) { // 重新发布接口
      this.lookData = {}
      const loading = ElLoading.service({
        lock: true,
        text: '查询中...'
      })
      const res = await updateInfo(item.id)
      if (res.data.code === 200) {
        // console.log(res)
        const result = res.data.data
        console.log(result)
        this.anewLookStatus = result.previewStatus

        const pmsProductPresaleExtraEntityListId = result.pmsProductPresaleExtraEntityList[0].id
        const advancePikegoodsDaysBegin = result.pmsProductPresaleExtraEntityList[0].advancePikegoodsDaysBegin
        const advancePikegoodsDaysEnd = result.pmsProductPresaleExtraEntityList[0].advancePikegoodsDaysEnd
        result.offerExpiryDate = moment(result.offerExpiryDate).format('YYYY-MM-DD')
        // console.log(result.offerExpiryDate)

        for (const i of result.skuStockList) {
          i.spData = JSON.parse(i.spData)
        }
        if (result.previewStatus === 1) { // 预售
          // 预售重新发布
          this.lookData = {
          // 基本信息
            formStatus: 2,
            id: result.id,
            previewStatus: result.previewStatus, // 是否为预售商品：0->不是；1->是
            name: result.name, // 商品名称
            subject_value: result.cmsCsSpecialSubjectEntity.id, // 已选品种(专题)
            subject_list: [], // 品种(专题)列表
            brand: result.brandName, // 品牌
            placeOfOrigin: result.originPlace, // 产地
            price: result.price, // 价格
            unitOfMeasurement_option: [{ value: '吨', label: '吨' }, { value: 'KG', label: 'KG' }], // 所有单位
            unitOfMeasurement_value: result.unit, // 已选单位  // 计量单位

            specification_dataTitle: ['规格', '交货日期', '销售价格', '库存', '属性图片', '操作'],
            specification_listData: result.skuStockList,
            specification_value: '', // 规格
            // 规格设置

            priceTerms_option: [
              { value: '自提', label: '自提' },
              { value: '物流', label: '物流' },
              { value: 'EXW', label: 'EXW' },
              { value: 'FOB', label: 'FOB' },
              { value: 'CIF', label: 'CIF' },
              { value: 'CFR', label: 'CFR' }
            ], // 所有报价方式
            priceTerms_value: result.offerForm, // 已选报价方式 // 报价方式
            offerTime: result.offerExpiryDate, // 报价有效日期

            currency_option: [{ value: '￥', label: '人民币' }, { value: '$', label: '美元' }], // 所有币种
            currency_value: result.currencySymbol, // 已选 // 币种
            modeOfPayment_option: [
              { value: '在线支付', label: '在线支付' },
              { value: 'T/T电汇', label: 'T/T电汇' },
              { value: 'D/P', label: 'D/P' },
              { value: 'D/A', label: 'D/A' },
              { value: 'L/C', label: 'L/C' },
              { value: 'D/D(票汇)', label: 'D/D(票汇)' },
              { value: 'M/T(信汇)', label: 'M/T(信汇)' }
            ], // 所有支付方式
            modeOfPayment_value: result.payWay, // 已选 支付方式
            advanceRatio: result.prePayRate, // 预付比例
            allowOverflowRatio: result.allowOverflow, // 允许溢折比例
            leastIncrement: result.minIncr, // 最小增量
            leastIndent: result.minBuy, // 最小订货量

            withholdingway_dataTitle: ['规格', '价格/单位', '操作'],
            withholdingway_listData: result.pmsProductPreTakeWayList,
            // withholdingway_value: '', // 预提方式
            turnMarket: result.isResale + '', // 是否允许转售
            // turnMarketTerminationTime: '', // 转售终止时间
            bringForwardTime: pmsProductPresaleExtraEntityListId, // 预提提前天数
            bringForwardTime_begin: advancePikegoodsDaysBegin, // 开始
            bringForwardTime_finish: advancePikegoodsDaysEnd, // 结束
            dealWarehouse_list: [], // 交收仓库
            dealWarehouse_value: result.deliveryPlace, // 选择的交收仓库(站点)
            supplier: '', // 供货商
            commodityPropertyParameter: '', // 商品属性参数
            productTime: result.productTime, // 产品年份
            decript: { // 商品介绍
              dialogImageUrl: ref(''), // 放大弹窗图片
              dialogVisible: ref(false), // 放大弹窗开关
              propertyImage: [], // 属性图片url
              url: [], // 图片地址
              decriptList: result.detailHtml // 商品介绍
            },
            albumPics: result.albumPics, // 商品主图
            pic: result.pic,
            manor_name: result.manorName, // 咖啡庄园名称
            altitude: result.altitude, // 种植海拔
            pick_way: result.pickWay, // 采摘方式
            process_list: [
              { value: '三重厌氧', label: '三重厌氧' },
              { value: '双重发酵日晒', label: '双重发酵日晒' },
              { value: '康普茶发西都日晒', label: '康普茶发西都日晒' },
              { value: '日晒', label: '日晒' },
              { value: '有氧发酵日晒', label: '有氧发酵日晒' },
              { value: '水洗', label: '水洗' },
              { value: '水洗96小时发酵', label: '水洗96小时发酵' },
              { value: '湿刨', label: '湿刨' },
              { value: '湿刨处理', label: '湿刨处理' },
              { value: '密处理', label: '密处理' },
              { value: '密处理(晚收批次)', label: '密处理(晚收批次)' },
              { value: '其他', label: '其他' }
            ], // 加工方式集合
            process_way: result.processWay, // 加工方式
            flavor_description: result.flavorDescription // 风味描述
          }
        } else if (result.previewStatus === 0) { // 现货
          // 现货重新发布
          let feightPrice = result.freightAbroad === null || result.freightAbroad === '' ? result.freightChina : result.freightAbroad
          const isfeightchose = result.freightAbroad === null || result.freightAbroad === '' ? '0' : '1'
          feightPrice = feightPrice.split('/')[0]
          this.lookData = {
          // 基本信息
            formStatus: 3, // 现货重新发布
            id: result.id,
            previewStatus: result.previewStatus, // 是否为预售商品：0->不是；1->是
            name: result.name, // 商品名称
            subject_value: result.cmsCsSpecialSubjectEntity.id, // 已选品种(专题)
            subject_list: [], // 品种(专题)列表
            brand: result.brandName, // 品牌
            placeOfOrigin: result.originPlace, // 产地
            price: result.price, // 价格
            unitOfMeasurement_option: [{ value: '吨', label: '吨' }, { value: 'KG', label: 'KG' }], // 所有单位
            unitOfMeasurement_value: result.unit, // 已选单位  // 计量单位

            specification_dataTitle: ['规格', '交货日期', '销售价格', '库存', '属性图片', '操作'],
            specification_listData: result.skuStockList,
            specification_value: '', // 规格
            // 规格设置

            priceTerms_option: [
              { value: '自提', label: '自提' },
              { value: '物流', label: '物流' },
              { value: 'EXW', label: 'EXW' },
              { value: 'FOB', label: 'FOB' },
              { value: 'CIF', label: 'CIF' },
              { value: 'CFR', label: 'CFR' }
            ], // 所有报价方式
            priceTerms_value: result.offerForm, // 已选报价方式 // 报价方式
            offerTime: result.offerExpiryDate, // 报价有效日期

            currency_option: [{ value: '￥', label: '人民币' }, { value: '$', label: '美元' }], // 所有币种
            currency_value: result.currencySymbol, // 已选 // 币种
            modeOfPayment_option: [
              { value: '在线支付', label: '在线支付' },
              { value: 'T/T电汇', label: 'T/T电汇' },
              { value: 'D/P', label: 'D/P' },
              { value: 'D/A', label: 'D/A' },
              { value: 'L/C', label: 'L/C' },
              { value: 'D/D(票汇)', label: 'D/D(票汇)' },
              { value: 'M/T(信汇)', label: 'M/T(信汇)' }
            ], // 所有支付方式
            modeOfPayment_value: result.payWay, // 已选 支付方式
            advanceRatio: result.prePayRate, // 预付比例
            allowOverflowRatio: result.allowOverflow, // 允许溢折比例
            leastIncrement: result.minIncr, // 最小增量
            leastIndent: result.minBuy, // 最小订货量

            withholdingway_dataTitle: ['规格', '价格/单位', '操作'],
            withholdingway_listData: result.pmsProductPreTakeWayList,
            // withholdingway_value: '', // 预提方式
            turnMarket: result.isResale + '', // 是否允许转售
            // turnMarketTerminationTime: '', // 转售终止时间
            bringForwardTime: pmsProductPresaleExtraEntityListId, // 预提提前天数
            bringForwardTime_begin: advancePikegoodsDaysBegin, // 开始
            bringForwardTime_finish: advancePikegoodsDaysEnd, // 结束
            dealWarehouse_list: [], // 交收仓库
            dealWarehouse_value: result.deliveryPlace, // 选择的交收仓库(站点)
            supplier: '', // 供货商
            commodityPropertyParameter: '', // 商品属性参数
            productTime: result.productTime, // 产品年份
            decript: { // 商品介绍
              dialogImageUrl: ref(''), // 放大弹窗图片
              dialogVisible: ref(false), // 放大弹窗开关
              propertyImage: [], // 属性图片url
              url: [], // 图片地址
              decriptList: result.detailHtml // 商品介绍
            },
            albumPics: result.albumPics, // 商品主图
            pic: result.pic,
            manor_name: result.manorName, // 咖啡庄园名称
            altitude: result.altitude, // 种植海拔
            introductionToTheEstate: result.introductionToTheEstate, // 庄园介绍
            grade: result.grade, // 等级
            authentication: result.authentication, // 认证
            cuppingReference: result.cuppingReference, // 杯测参考
            treeSpecies: result.treeSpecies, // 树种
            pick_way: result.pickWay, // 采摘方式
            process_list: [
              { value: '三重厌氧', label: '三重厌氧' },
              { value: '双重发酵日晒', label: '双重发酵日晒' },
              { value: '康普茶发西都日晒', label: '康普茶发西都日晒' },
              { value: '日晒', label: '日晒' },
              { value: '有氧发酵日晒', label: '有氧发酵日晒' },
              { value: '水洗', label: '水洗' },
              { value: '水洗96小时发酵', label: '水洗96小时发酵' },
              { value: '湿刨', label: '湿刨' },
              { value: '湿刨处理', label: '湿刨处理' },
              { value: '密处理', label: '密处理' },
              { value: '密处理(晚收批次)', label: '密处理(晚收批次)' },
              { value: '其他', label: '其他' }
            ], // 加工方式集合
            process_way: result.processWay, // 加工方式
            flavor_description: result.flavorDescription, // 风味描述

            // 现货数据
            isfeightchose: isfeightchose,
            feightPrice: feightPrice // 运费 freightAbroad 国外   freightChina 国内
          }
        }

        this.lookshow = true
        console.log(this.lookData)
      }
      loading.close()
    },
    closeDilog (e) {
      // console.log(e)
      this.lookData = {}
      this.lookshow = false
    },
    fenleichange () {
      this.initData(1)
    }
  },
  mounted () {
    this.initData(1)
    this.getProductAttribute()
    emitter.on('againSubon', () => {
      // 发布成功
      ElMessage.success({
        message: '重新发布成功，请刷新数据'
      })
      this.lookshow = false
    })
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    return {
      handleSizeChange
    }
  }
}
</script>

<style lang='scss' >
//@import ''; 引入公共css类
.cont{
  padding: 20px;
  .title{
    height: 50px;
  }
  .filter-container{
    .el-card__body{
      .selectname{
        display: inline-block;
        margin-right: 20px;
        width: 400px;
      }
    }
  }
  .order_body{
    tr{
      display: flex;
      th{
        height: 40px;
        line-height: 40px;
        background: #F7F7F7;
        border: 1px solid #EAEAEA;
        text-align: left;
        box-sizing: border-box;
        padding: 0 20px;
        flex: 1;
        white-space: normal;
        overflow: hidden;
        word-break: break-all;
      }
      .content{
        flex: 1;
        display: flex;
        flex-flow: column;
        .order_body_ul{
          flex: 1;
          display: flex;
          .order_body_li{
            font-size: 14px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 65px;
            box-sizing: border-box;
            padding: 14px 20px;
            border: 1px solid #EAEAEA;
            .money_span,p{
              color: #333333;
            }
            .el-tag{
              margin:0 10px;
            }
          }
          .body_num6{
            display: flex;
            // justify-content: space-around;
            // align-items: center;
            span{
              font-size: 14px;
              cursor: pointer;
              margin-right: 30px;
            }
            .order_detailcss{
              color: #C6824E;
            }
          }
        }
        .nodata{
          width: 100%;
          // margin: 0 auto;
          min-height: 800px;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            margin: 0 auto;
            height: 300px;
          }
        }
      }
    }
  }
  .showfooter{
    height: 100px;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
