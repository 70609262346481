<template>
  <el-card class="form-container" shadow="never">
    <el-steps :active="active" finish-status="success" align-center>
      <el-step title="选择商品基本分类"></el-step>
      <el-step title="填写商品信息"></el-step>
    </el-steps>
    <select-product-class-vue
      v-show="showStatus[0]"
      :value="productParam"
      :is-edit="false"
      @nextStep="nextStep"></select-product-class-vue>
    <selec-produ-details-vue
      v-show="showStatus[1]"
      :value="productParam"
      :is-edit="false"
      @prevStep="prevStep"
      @finishCommit="finishCommit"></selec-produ-details-vue>
  </el-card>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import http from '../../../../api/http'
import selecProduDetailsVue from './selecProduDetails.vue'
import selectProductClassVue from './selectProductClass.vue'

const defaultProductParam = {
  productCategoryId: null, // 分类id
  productAttributeCategoryId: null, // 属性类型id
  productCategoryName: '', // 分类名称
  previewStatus: 0, // 是否为预售商品：0->不是；1->是
  productParam: null,
  qualityStandard: null,
  brandName: '',
  // brand: this.formData.brand, // 品牌
  currencySymbol: '￥',
  detailHtml: '',
  isResale: '',
  minBuy: '',
  minIncr: '',
  name: '',
  offerExpiryDate: '', // this.formData.offerTime + ' 23:59:59'
  offerForm: '',
  originPlace: '', // 产地
  albumPics: '',
  presaleWay: 1, // 预售方式
  allowOverflow: '', // 允许溢价比例
  payWay: '',
  pic: '',
  pmsProductPreTakeWayList: [], // 预提方式List
  pmsProductPresaleExtraEntityList: [
    {
      id: '',
      advancePikegoodsDaysBegin: '',
      advancePikegoodsDaysEnd: ''
    }
  ],
  pmsSiteList: [{
    id: ''
  }], // 交收仓库选择
  cmsCsSpecialSubjectEntityList: [
    {
      id: ''
    }
  ], // 品种（专题）信息
  // pmsExchangeSkuStockEntity: null,
  productAttributeValueList: [{}],
  subjectProductRelationList: [{}],
  prePayRate: '',
  price: '',
  deliveryPlace: '', // 交收仓库
  skuStockList: [], // 规格List
  unit: '', // 计量单位,
  freightChina: '',
  freightAbroad: '',
  manorName: '',
  altitude: '',
  productTime: '',
  pickWay: '',
  flavorDescription: '',
  processWay: '',
  introductionToTheEstate: '', // 庄园介绍
  grade: '', // 等级
  authentication: '', // 认证
  cuppingReference: '', // 杯测参考
  treeSpecies: '' // 树种
}
// 创建发布商品
const productCreate = (data) => {
  return http.post('/mall-merchant/product/create', data, true)
}
export default {
  name: 'releaseCommFrom',
  data () {
    return {
      active: 0,
      productParam: Object.assign({}, defaultProductParam),
      showStatus: [true, false]
    }
  },
  components: { selectProductClassVue, selecProduDetailsVue },
  methods: {
    hideAll () {
      for (let i = 0; i < this.showStatus.length; i++) {
        this.showStatus[i] = false
      }
    },
    prevStep () {
      if (this.active > 0 && this.active < this.showStatus.length) {
        this.active--
        this.hideAll()
        this.showStatus[this.active] = true
      }
    },
    nextStep () {
      if (this.active < this.showStatus.length - 1) {
        this.active++
        this.hideAll()
        this.showStatus[this.active] = true
      }
    },
    finishCommit (isEdit) {
      this.productParam.offerExpiryDate = this.productParam.offerExpiryDate + ' 23:59:59'
      console.log(this.productParam)
      this.$confirm('是否要提交该商品', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service({
          lock: true,
          text: '请稍等...'
        })
        productCreate(this.productParam).then(res => {
          console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({
              message: '发布成功!'
            })
          } else {
            ElMessage.warning({
              message: res.data.msg
            })
          }
          loading.close()
        }).catch(err => {
          console.log(err)
          loading.close()
        })
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  mounted () {

  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.content_form{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
</style>
