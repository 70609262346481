<template>
<div>
  <el-upload
      list-type="picture"
      :before-upload="guigebeforeUpload"
      :on-success="guigeuploadSuccess"
      :on-preview="handlePictureCardPreview"
      :on-remove="removePreview"
      :action="upLoadUrl"
      :data="upLoadData"
      :on-change="guigeonChange"
      accept="jpg/jpeg/png"
      v-model:file-list="guigepic"
    >
    <p>{{guigepic[0]?$t('m.merchantworkbench.replace'):$t('m.merchantworkbench.upload')}}</p>
  </el-upload>
  <el-dialog v-model="dialogVisible">
    <el-image style="width: 100%;" :src="dialogImageUrl" />
  </el-dialog>
</div>
</template>

<script>
import { ref } from 'vue'
import http from '../../../../api/http'
import { ElMessage } from 'element-plus'
export default {
  name: 'guigeUpload',
  data () {
    return {
      // 上传数据
      dir: null,
      fileList: ref([]),
      guigepic: ref([]),
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      },
      dialogImageUrl: ref(''), // 放大弹窗图片
      dialogVisible: ref(false) // 放大弹窗开关
    }
  },
  props: {
    value: Number
  },
  computed: {
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined
      },
      set: function (newValue) {
      }
    }
  },
  components: {},
  methods: {
    handlePictureCardPreview (file) { // 图片放大方法
      console.log(file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true // 打开弹窗
    },
    guigebeforeUpload (file) { // 规格上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 200 // 这里做文件大小限制
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg格式!',
          type: 'warning'
        })
        return false
      }
      if (!isLt2M) {
        ElMessage({
          message: '上传文件大小不能超过 200k!',
          type: 'warning'
        })
        return false
      }
      const param = {
        prefix: 'guige/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            console.log(res)
            this.dir = res.data.data.dir
            this.upLoadData.key = `${res.data.data.dir}/${file.name}`
            this.upLoadData.policy = res.data.data.policy
            this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            this.upLoadData.signature = res.data.data.signature
            this.upLoadUrl = res.data.data.host
            // this.formData.specification_listData[this.imgIndex].price = file
            // console.log(file)
            // console.log(this.formData.specification_listData)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    guigeuploadSuccess (res, file) { // 规格图片改变触发方法
      // console.log(file)
      // this.showFileList = true
      // this.formData.specification_listData.pop()
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      this.$emit('updateValue', { url: url, index: this.$props.value })
      this.guigepic[this.imgIndex] = file
      // console.log(url)
      // console.log(this.$props.value.skuStockList)
    },
    guigeonChange (file, fileList) { // 改变触发
      // console.log(file)
      console.log(fileList)
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name
      this.$emit('updateValue', { url: url, index: this.$props.value })
      this.guigepic = fileList
      // console.log(url)
      // this.guigeImgshowList(file.name)
      // console.log(this.$props)
      // console.log(this.$props.value)
    },
    removePreview (file, fileList) {
      // console.log(file)
      // console.log(fileList)
      this.guigepic = fileList
      this.$emit('updateValue', { url: null, index: this.$props.value })
    }
  },
  mounted () {

  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
/deep/ .updata_img{
  align-items: center;
  div{
    height: 46px;
    .el-upload-list{
      // padding: 0;
      height: 46px;
      // width: 0;
      .el-upload-list__item{
        margin-bottom: 0;
        height: 46px;
        width: 48px;
      }
    }
    .el-upload--picture-card{
      border: 0;
      height: 0;
      width: 0;
      p{
        height: 46px;
        color: #C6824E;
        cursor: pointer;
        word-wrap:break-word;
      }
    }
  }
}
</style>
