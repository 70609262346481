<!-- eslint-disable vue/no-mutating-props -->
<template>
<div class="selectclass_div" style="margin-top: 50px">
  <el-form :model="value" :rules="rules" ref="productInfoForm" size="small" label-width="120px">
    <!-- <el-form-item :label="$t('m.index.variety')+'：'" prop="subject_value">
      <el-select v-model="value.cmsCsSpecialSubjectEntityList[0].id" size="small">
        <el-option
          v-for="item in subject_list"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item :label="$t('m.merchantworkbench.Unit_of_measurement')+'：'" prop="unit"> <!-- 计量单位 -->
      <el-input  maxlength="5" v-model="value.unit" size="small"></el-input>
    </el-form-item>
    <el-form-item :label="'属性类型'+'：'" prop="productAttributeCategoryId"> <!-- 属性类型 -->
      <el-select v-model="value.productAttributeCategoryId" size="small" @change="handleProductAttrChange" :disabled="isDisabled">
        <el-option
          v-for="item in productAttributeCategoryOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Specification_settings')+'：'" class="sptise" required> <!-- 规格 -->
      <el-card shadow="never" class="cardBg">
        <div v-for="(productAttr,idx) in selectProductAttr" :key="idx">
          {{productAttr.name}}：
          <el-checkbox-group v-if="productAttr.handAddStatus===0" v-model="selectProductAttr[idx].values"
                            @change="handleRefreshProductSkuList">
            <el-checkbox v-for="item in getInputListArr(productAttr.inputList)" :label="item"
                          :key="item"
                          class="littleMarginLeft"></el-checkbox>
          </el-checkbox-group>
          <div v-else>
            <el-checkbox-group v-model="selectProductAttr[idx].values"
                              @change="handleRefreshProductSkuList">
              <div v-for="(item,index) in selectProductAttr[idx].options" :key="index" style="display: inline-block"
                    class="littleMarginLeft">
                <el-checkbox :label="item" :key="item"></el-checkbox>
                <!--<el-button type="text" class="littleMarginLeft" @click="handleRemoveProductAttrValue(idx,index)">删除
                </el-button>-->
            </div>
            </el-checkbox-group>
            <el-input size="small" v-model="addProductAttrValue" style="width: 240px;margin-left: 10px" clearable>
              <template #append>
                <el-button size="small" class="littleMarginLeft" @click="handleAddProductAttrValue(idx)">增加</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </el-card>
      <el-table style="width: 100%;margin-top: 20px"
                  :data="value.skuStockList"
                  border>
            <el-table-column
              v-for="(item,index) in selectProductAttr"
              :label="item.name"
              :key="item.id"
              align="center">
              <template #default="scope">
                {{getProductSkuSp(scope.row,index)}}
              </template>
            </el-table-column>

            <el-table-column
              label="销售价格"
              align="center">
              <template #default="scope">
                <el-input style="max-width: 200px;" size="small" v-model="scope.row.price"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="商品库存"
              align="center">
              <template #default="scope">
                <el-input style="max-width: 200px;" size="small" v-model="scope.row.stock"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="属性图片：" width="200" align="left">
                <template #default="scope">
                  <guige-upload-vue :value="scope.$index"
                    @updateValue="updateValue"></guige-upload-vue>
                </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="80"
              align="center"
              v-if="!isDisabled">
              <template #default="scope">
                <el-button
                  type="text"
                  @click="handleRemoveProductSku(scope.$index, scope.row)">删除
                </el-button>
              </template>
            </el-table-column>
        </el-table>
    </el-form-item>
    <el-form-item label="商品参数：" required>
      <el-card shadow="never" class="cardBg">
        <div v-for="(item,index) in selectProductParam" :key="index" :class="{littleMarginTop:index!==0}">
          <div class="paramInputLabel">{{item.name}}:</div>
          <el-select v-if="item.inputType===1&&item.name!='品种'" class="paramInput" v-model="selectProductParam[index].value"><!-- 其他参数且不包含咖啡品种 -->
            <el-option
              v-for="item in getParamInputList(item.inputList)"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <el-select v-if="item.inputType===1&&item.name=='品种'" class="paramInput" v-model="value.cmsCsSpecialSubjectEntityList[0].id"><!-- 咖啡品种 -->
            <el-option
              v-for="item in subject_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input v-if="item.inputType===0" class="paramInput" v-model="selectProductParam[index].value"></el-input>
        </div>
      </el-card>
    </el-form-item>
    <el-form-item :label="$t('m.reservation_varietiy_details.way_of_quoting')+'：'" prop="offerForm"> <!-- 报价方式 -->
      <el-input v-model="value.offerForm"  size="small"></el-input>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Expiry_date_of_offer')+'：'" prop="offerExpiryDate"> <!-- 报价有效期 -->
      <div class="block">
        <el-date-picker v-model="value.offerExpiryDate"
          size="small"
          type="date" :placeholder="$t('m.merchantworkbench.option_date')"
          value-format="YYYY-MM-DD"
          :disabledDate="disabledDate"
          >
        </el-date-picker>
      </div>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Currency')+'：'" prop="currency_value"> <!-- 货币币种 -->
      <el-select v-model="value.currencySymbol"
      size="small">
        <el-option
          v-for="item in currency_option"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('m.buy_settleaccounts.Payment_Methods')+'：'" prop="modeOfPayment_value"><!-- 支付方式 -->
      <el-select v-model="value.payWay"
      size="small">
        <el-option
          v-for="item in modeOfPayment_option"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Settlement_warehouse')+'：'" prop="dealWarehouse_value"> <!-- 交收仓库 -->
        <el-select v-model="value.deliveryPlace" @change="deliveryPlaceChange"
          size="small">
            <el-option
              v-for="item in dealWarehouse_list"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Minimum_increment')+'：'" prop="minIncr"><!-- 最小增量 -->
      <el-input maxlength="8" v-model="value.minIncr"
      @change="confirmblAdbill('zl')" size="small"></el-input>
    </el-form-item>
    <el-form-item :label="$t('m.reservation_varietiy_details.Minimum_Order_Quantity')+'：'" prop="minBuy"> <!-- 最小订货量 -->
      <el-input maxlength="8" v-model="value.minBuy"
      @change="confirmblAdbill('dh')" size="small"></el-input>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Freight')+'：'" required> <!-- 运费 -->
      <el-radio-group v-model="isfeightchose" @change="isfeightchoseChang">
        <el-radio label="0" size="mini">{{$t('m.merchantworkbench.domestic')}}</el-radio>
        <el-radio label="1" size="mini">{{$t('m.merchantworkbench.foreign')}}</el-radio>
      </el-radio-group>
      <br>
      {{value.currencySymbol}} <el-input style="max-width: 100px"
      v-model="feightPrice"  maxlength="8"
      @change="confirmblAdbill('yunfei')"
      size="mini"></el-input>{{" / "+value.unit}}
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Main_product_image')+'：'" required> <!-- 商品主图 -->
      <main-img-upload-vue @updateMainImgValue="updateMainImgValue"></main-img-upload-vue>
      <div class="decripyt_div">
        <span class="decripyt_div_sapn">{{$t('m.merchantworkbench.Upload_up')}}</span>
        <p class="decripyt_div_p">{{$t('m.merchantworkbench.Supports_JPG')}}</p>
      </div>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.Product_detail_page')+'：'">
      <p class="pppp">（{{$t('m.merchantworkbench.Note_Please')}}）</p>
    </el-form-item>
    <el-form-item>  <!-- 商品详情 -->
      <div id="editorshow">
        <editor-show @EditorFrom="editorDataList"></editor-show>
      </div>
    </el-form-item>
    <el-form-item style="text-align: center;margin-top: 30px" label-width="0px">
      <el-button size="small" @click="handlePrev">上一步，填写商品基本分类</el-button>
      <el-button type="primary" size="small" @click="handleFinishCommit">完成，发布商品</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import { ref } from 'vue'
import http from '../../../../api/http'
import guigeUploadVue from './guigeUpload.vue'
import mainImgUploadVue from './mainImgUpload.vue'
import EditorShow from '../../../../components/EditorShow.vue'
import { ElMessage } from 'element-plus'

// 品种(专题)数据
const topicList = (data) => {
  return http.get('/mall-portal/home/topicList')
}
// 初始化站点数据-交收仓库
const pmsSiteSelectList = (data) => {
  return http.get('/mall-portal/pmsSite/selectList', data)
}
// 获取所有商品属性分类
const productAttributeList = (data) => {
  return http.get('/mall-merchant/productAttribute/category/list', data)
}
// 根据分类查询属性列表或参数列表 type 0表示属性，1表示参数
const productAttribute = (id, param) => {
  return http.get(`/mall-merchant/productAttribute/list/${id}`, param)
}
export default {
  name: 'selecProduDetails',
  data () {
    return {
      subject_list: [],
      dealWarehouse_list: [], // 站点交收仓库
      addProductAttrValue: '', // 可手动添加的商品属性
      selectProductAttr: [], // 选中的商品属性
      currency_option: [{ value: '￥', label: '人民币' }, { value: '$', label: '美元' }], // 所有币种
      isfeightchose: '0',
      feightPrice: 0, // 运费 freightAbroad 国外   freightChina 国内
      modeOfPayment_option: [
        { value: '在线支付', label: '在线支付' },
        { value: '其他', label: '其他' }
      ], // 所有支付方式
      productAttributeCategoryOptions: [], // 商品属性分类下拉选项

      selectProductParam: [], // 选中的商品参数
      ProductAttrArr: [],
      imgIndex: ref(''), // 当前的第几个规格

      // unitOfMeasurement_option: [{ value: '吨', label: '吨' }, { value: 'KG', label: 'KG' }], // 所有单位
      rules: {
        productCategoryId: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        subject_value: [{ required: true, message: '请选择品种', trigger: 'blur' }],
        brandName: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
        price: [{ required: true, message: '请输入正确的价格', trigger: 'blur' }],
        placeOfOrigin: [{ required: true, message: '请输入产地', trigger: 'blur' }],
        unit: [{ required: true, message: '请输入计量单位', trigger: 'blur' }],
        productAttributeCategoryId: [{ required: true, message: '请选择属性类型', trigger: 'blur' }],
        offerForm: [{ required: true, message: '请选择报价方式', trigger: 'blur' }],
        currency_value: [{ required: true, message: '请选择币种', trigger: 'blur' }],
        offerExpiryDate: [{ required: true, message: '请选择报价日期', trigger: 'blur' }],
        modeOfPayment_value: [{ required: true, message: '请选择支付方式', trigger: 'blur' }],
        advanceRatio: [{ required: true, message: '请输入预付比例(1-100)', trigger: 'blur' }],
        minIncr: [{ required: true, message: '请输入最小增量', trigger: 'blur' }],
        allowOverflowRatio: [{ required: true, message: '请输入溢折比例(1-10)', trigger: 'blur' }],
        minBuy: [{ required: true, message: '请输入最小订单量', trigger: 'blur' }],
        bringForwardTime_begin: [{ required: true, message: '请输入正确的预提提前天数', trigger: 'blur' }],
        dealWarehouse_value: [{ required: true, message: '请选择交收仓库', trigger: 'blur' }],
        feightPrice: [{ required: true, message: '请输入正确的运费价格', trigger: 'blur' }],
        manor_name: [{ required: true, message: '请输入咖啡庄园', trigger: 'blur' }],
        introductionToTheEstate: [{ required: true, message: '请输入咖啡庄园介绍', trigger: 'blur' }],
        grade: [{ required: true, message: '请输入咖啡等级', trigger: 'blur' }],
        authentication: [{ required: true, message: '请输入认证', trigger: 'blur' }],
        cuppingReference: [{ required: true, message: '请输入杯测参考', trigger: 'blur' }],
        treeSpecies: [{ required: true, message: '请输入树种', trigger: 'blur' }],
        productTime: [{ required: true, message: '请选择采摘年份', trigger: 'blur' }],
        altitude: [{ required: true, message: '请输入正确的种植海拔', trigger: 'blur' }],
        pick_way: [{ required: true, message: '请输入采摘方式', trigger: 'blur' }],
        // process_way: [{ required: true, message: '请选择加工方法', trigger: 'blur' }],
        flavor_description: [{ required: true, message: '请输入风味描述', trigger: 'blur' }]
      }
    }
  },
  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled: function () {
      return this.$props.value.auditStatus === 1
    }
  },
  components: { guigeUploadVue, mainImgUploadVue, EditorShow },
  methods: {
    getProductAttrCateList () { // 查询属性类型
      const param = { pageNum: 1, pageSize: 100 }
      productAttributeList(param).then(response => {
        this.productAttributeCategoryOptions = []
        if (response.data.code === 200) {
          const list = response.data.data.records
          for (let i = 0; i < list.length; i++) {
            this.productAttributeCategoryOptions.push({ label: list[i].name, value: list[i].id })
          }
        }
      })
    },
    confirmblAdbill (str) { // 输入内容时正则判断数据
      switch (str) {
        case 'zl':
          if (!/^[1-9]\d*$/.test(this.$props.value.minIncr)) {
            this.$props.value.minIncr = ''
          }
          break
        case 'dh' :
          if (!/^[1-9]\d*$/.test(this.$props.value.minBuy)) {
            this.$props.value.minBuy = ''
          }
          break
        case 'yunfei': // 运费
          if (!/(^[0-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.feightPrice)) {
            // console.log('不通过', this.feightPrice)
            this.feightPrice = 0
          } else {
            if (this.isfeightchose === '0') {
              this.$props.value.freightChina = this.feightPrice + '/1'
              this.$props.value.freightAbroad = null
            } else {
              this.$props.value.freightAbroad = this.feightPrice + '/1'
              this.$props.value.freightChina = null
            }
          }
          break
        default:
          break
      }
    },
    handleProductAttrChange (value) {
      this.selectProductAttr = []
      this.$props.value.skuStockList = []
      this.getProductAttrList(0, value) // 获取属性
      this.getProductAttrList(1, value) // 获取参数
    },
    getProductAttrList (type, cid) {
      const param = { pageNum: 1, pageSize: 100, type: type }
      productAttribute(cid, param).then(response => {
        console.log(response)
        const list = response.data.data.list
        if (type === 0) {
          this.selectProductAttr = []
          for (let i = 0; i < list.length; i++) {
            let options = []
            let values = []
            if (this.isEdit) {
              if (list[i].handAddStatus === 1) {
                // 编辑状态下获取手动添加编辑属性
                options = this.getEditAttrOptions(list[i].id)
              }
              // 编辑状态下获取选中属性
              values = this.getEditAttrValues(i)
            }
            this.selectProductAttr.push({
              id: list[i].id,
              name: list[i].name,
              handAddStatus: list[i].handAddStatus,
              inputList: list[i].inputList,
              values: values,
              options: options
            })
          }
          if (this.isEdit) {
            // 编辑模式下刷新商品属性图片
            this.refreshProductAttrPics()
            console.log('编辑模式，刷新商品属性图片')
          }
        } else {
          this.selectProductParam = []
          for (let i = 0; i < list.length; i++) {
            let value = null
            if (this.isEdit) {
              // 编辑模式下获取参数属性
              value = this.getEditParamValue(list[i].id)
            }
            this.selectProductParam.push({
              id: list[i].id,
              name: list[i].name,
              value: value,
              inputType: list[i].inputType,
              inputList: list[i].inputList
            })
          }
        }
      })
    },
    handleRefreshProductSkuList () {
      console.log(this.ProductAttrArr)
      if (this.ProductAttrArr.length < 1) {
        this.ProductAttrArr = [...this.$props.value.skuStockList]
      } else {
        this.ProductAttrArr = ''
      }
      this.refreshProductAttrPics()
      this.refreshProductSkuList()
    },
    refreshProductAttrPics () {
      // console.log('刷新商品属性图片-------')
      this.selectProductAttrPics = []
      if (this.selectProductAttr.length >= 1) {
        const values = this.selectProductAttr[0].values
        for (let i = 0; i < values.length; i++) {
          let pic = null
          if (this.isEdit) {
            // 编辑状态下获取图片
            pic = this.getProductSkuPic(values[i])
          }
          this.selectProductAttrPics.push({ name: values[i], pic: pic })
        }
        console.log(this.selectProductAttrPics)
      }
    },
    refreshProductSkuList () {
      this.$props.value.skuStockList = []
      const skuList = this.$props.value.skuStockList
      // 只有一个属性时
      if (this.selectProductAttr.length === 1) {
        const attr = this.selectProductAttr[0]
        for (let i = 0; i < attr.values.length; i++) {
          skuList.push({
            spData: JSON.stringify([{ key: attr.name, value: attr.values[i] }])
          })
        }
        // console.log(skuList)
      } else if (this.selectProductAttr.length === 2) {
        const attr0 = this.selectProductAttr[0]
        const attr1 = this.selectProductAttr[1]
        for (let i = 0; i < attr0.values.length; i++) {
          if (attr1.values.length === 0) {
            skuList.push({
              spData: JSON.stringify([{ key: attr0.name, value: attr0.values[i] }])
            })
            continue
          }
          for (let j = 0; j < attr1.values.length; j++) {
            const spData = []
            spData.push({ key: attr0.name, value: attr0.values[i] })
            spData.push({ key: attr1.name, value: attr1.values[j] })
            skuList.push({
              spData: JSON.stringify(spData)
            })
          }
        }
      } else {
        const attr0 = this.selectProductAttr[0]
        const attr1 = this.selectProductAttr[1]
        const attr2 = this.selectProductAttr[2]
        const attr3 = this.selectProductAttr[3]
        for (let i = 0; i < attr0.values.length; i++) {
          if (attr1.values.length === 0) {
            skuList.push({
              spData: JSON.stringify([{ key: attr0.name, value: attr0.values[i] }])
            })
            continue
          }
          for (let j = 0; j < attr1.values.length; j++) {
            if (attr2.values.length === 0) {
              const spData = []
              spData.push({ key: attr0.name, value: attr0.values[i] })
              spData.push({ key: attr1.name, value: attr1.values[j] })
              skuList.push({
                spData: JSON.stringify(spData)
              })
              continue
            }
            for (let k = 0; k < attr2.values.length; k++) {
              if (attr3.values.length === 0) {
                const spData = []
                spData.push({ key: attr0.name, value: attr0.values[i] })
                spData.push({ key: attr1.name, value: attr1.values[j] })
                spData.push({ key: attr2.name, value: attr2.values[k] })
                skuList.push({
                  spData: JSON.stringify(spData)
                })
                continue
              }
              for (let l = 0; l < attr3.values.length; l++) {
                const spData = []
                spData.push({ key: attr0.name, value: attr0.values[i] })
                spData.push({ key: attr1.name, value: attr1.values[j] })
                spData.push({ key: attr2.name, value: attr2.values[k] })
                spData.push({ key: attr3.name, value: attr3.values[l] })
                skuList.push({
                  spData: JSON.stringify(spData)
                })
              }
            }
          }
        }
      }
      // this.setSkuDefaultVal(this.value.skuStockList);
    },
    handleAddProductAttrValue (idx) { // 手动增加规格
      const options = this.selectProductAttr[idx].options
      if (this.addProductAttrValue == null || this.addProductAttrValue === '') {
        ElMessage({
          message: '属性值不能为空',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (options.indexOf(this.addProductAttrValue) !== -1) {
        ElMessage({
          message: '属性值不能重复',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.selectProductAttr[idx].options.push(this.addProductAttrValue)
      this.addProductAttrValue = null
    },
    handleRemoveProductSku (index, row) { // 删除规格
      const list = this.value.skuStockList
      if (list.length === 1) {
        list.pop()
      } else {
        list.splice(index, 1)
      }
    },
    updateValue (data) { // 更新规格图片触发
      // console.log(data)
      // console.log(this.imgIndex)
      this.$props.value.skuStockList[data.index].pic = data.url
    },
    updateMainImgValue (data) { // 更新主图图片触发
      // console.log(data)
      const albumPics = data.map((item) => { return item.url }).join(',') // 图片拼接成字符串
      // console.log(albumPics)
      const pic = data[0].url
      this.$props.value.albumPics = albumPics
      this.$props.value.pic = pic
    },
    isfeightchoseChang (e) { // 更改运费触发
      // console.log(e)
      // console.log(typeof e)
      this.$props.value.freightChina = null
      this.$props.value.freightAbroad = null
      this.feightPrice = 0
      // if (e === '0') {
      //   this.$props.value.freightChina = this.feightPrice + '/1'
      // } else {
      //   this.$props.value.freightAbroad = this.feightPrice + '/1'
      // }
      // e === 0 ? '' : ''
    },
    getParamInputList (inputList) {
      return inputList.split(',')
    },
    getProductSkuSp (row, index) {
      const spData = JSON.parse(row.spData)
      if (spData != null && index < spData.length) {
        return spData[index].value
      } else {
        return null
      }
    },
    getInputListArr (inputList) {
      return inputList.split(',')
    },
    handlePrev () {
      this.$emit('prevStep')
    },
    mergeProductAttrValue () { // 合并属性值 处理发布参数
      this.confirmblAdbill('yunfei')
      this.$props.value.productAttributeValueList = []
      for (const item of this.selectProductParam) {
        // console.log(item)
        if (item.name === '品种') {
          continue
        }
        this.$props.value.productAttributeValueList.push({
          productAttributeId: item.id,
          value: item.value
        })
      }
    },
    handleFinishCommit () { // 触发父组件发布
      this.mergeProductAttrValue()
      this.$emit('finishCommit', this.isEdit)
    },
    initTopicList () { // 查品种专题
      topicList().then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.subject_list = res.data.data
        }
        // 重新发布时
      }).catch(err => {
        console.log(err)
      })
    },
    initPmsSiteSelectList () { // 查站点交收仓库
      pmsSiteSelectList({
        pageNum: 50,
        pageSize: 1
      }).then(res => {
        // console.log(res)
        if (res.data.code === 200) {
          this.dealWarehouse_list = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    deliveryPlaceChange (item) { // 站点更新改
      // console.log(item)
      for (const i of this.dealWarehouse_list) {
        // eslint-disable-next-line eqeqeq
        if (i.name == item) {
          this.$props.value.pmsSiteList = [{
            id: i.id
          }]
          break
        }
      }
    },
    editorDataList (data) { // 富文本传值
      // console.log(data)
      this.$props.value.detailHtml = data
    }
  },
  mounted () {
    this.initTopicList()
    this.initPmsSiteSelectList()
    this.getProductAttrCateList()
  },
  setup (props, ctx) {
    const disabledDate = (time) => {
      // 此条为设置禁止用户选择今天之前的日期，不包含今天。
      return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000))
    }
    return {
      disabledDate
    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.selectclass_div{
  .el-form{
    .el-form-item{
      .el-form-item__label{
        min-width: 100px;
      }
      .el-form-item__content{
        .el-input{
          width: 500px;
        }
        .paramInputLabel {
          display: inline-block;
          width: 100px;
          text-align: right;
          padding-right: 10px
        }
        .littleMarginTop{
          margin-top: 10px;
        }
        #editorshow{
          width:  1000px;
          // height: 400px;
          // background: transparent;
        }
      }
    }
  }
}
</style>
