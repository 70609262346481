<template>
<!-- 仓储信息 -->
<div class="war_body">
  <div class="addbtn">
    <el-button type="warning" size="small" @click="addbtn()">添加</el-button>
  </div>
  <el-table id="out-table" ref="productTable" :data="list" style="width: 100%" v-loading="listLoading" border>
      <el-table-column label="仓库名称" width="" align="center">
        <template #default="scope">{{scope.row.warehouseName}}</template>
      </el-table-column>
      <el-table-column label="收货单号" width="" align="center">
        <template #default="scope">{{scope.row.receiptNumber}}</template>
      </el-table-column>
      <el-table-column label="用户" width="" align="center">
        <template #default="scope">{{scope.row.member}}</template>
      </el-table-column>
      <el-table-column label="期望入库时间" width="" align="center">
        <template #default="scope">{{scope.row.expectedReceiptTime}}</template>
      </el-table-column>
      <el-table-column label="通知时间" width="" align="center">
        <template #default="scope">{{scope.row.noticeTime}}</template>
      </el-table-column>
      <el-table-column label="商品名称" width="" align="center">
        <template #default="scope">{{scope.row.productName}}</template>
      </el-table-column>
      <el-table-column label="件数" width="" align="center">
        <template #default="scope">{{scope.row.numberOfPieces}}</template>
      </el-table-column>
      <el-table-column label="数量" width="" align="center">
        <template #default="scope">{{scope.row.number}}</template>
      </el-table-column>
      <el-table-column label="法定数量" width="" align="center">
        <template #default="scope">{{scope.row.legalNumber}}</template>
      </el-table-column>
      <el-table-column label="实际入库时间" width="" align="center">
        <template #default="scope">{{scope.row.actualReceiptTime}}</template>
      </el-table-column>
      <el-table-column label="实收件数" width="" align="center">
        <template #default="scope">{{scope.row.actualNumberOfReceivedItems}}</template>
      </el-table-column>
      <el-table-column label="实收数量" width="" align="center">
        <template #default="scope">{{scope.row.netReceiptsNumber}}</template>
      </el-table-column>
      <el-table-column label="审核状态" width="" align="center">
        <template #default="scope">{{
        scope.row.auditStatus==0?'未审核':
        scope.row.auditStatus==1?'审核通过':
        scope.row.auditStatus==2?'审核不通过':
        '未知状态'}}
        <el-tag v-if="scope.row.auditStatus==2" size="small" type="warning">{{scope.row.reviewComments}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template #default="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  <div class="pagination-container"  v-if="total<listQuery.pageSize?true:true">
    <el-pagination
      background
      :page-size="listQuery.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="total"
      v-model:current-page="listQuery.pageNum"
      layout="prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>
  </div>
  <div class="log_dialog">
    <el-dialog
      v-model="dialogVisible"
      :title="!countryIntro.id ? '新增' : '修改'"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form :model="countryIntro"
             :rules="rules"
             ref="countryIntroFrom"
             label-width="150px"
             size="small">
        <el-form-item label="仓库名称：" prop="warehouseId">
          <el-select v-model="countryIntro.warehouseId" filterable placeholder="请选择"
          :loading="warehouselistLoading"
          @change="warehouseName">
            <el-option
              v-for="item in warehouseoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="期望入库时间：" prop="expectedReceiptTime">
          <el-date-picker
            v-model="countryIntro.expectedReceiptTime"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知时间：" prop="noticeTime">
          <el-date-picker
            v-model="countryIntro.noticeTime"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="收货单号：" prop="receiptNumber">
          <el-input v-model="countryIntro.receiptNumber" class="input-width"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：" prop="productName">
          <el-input v-model="countryIntro.productName" class="input-width"></el-input>
        </el-form-item>
        <el-form-item label="件数：" prop="numberOfPieces">
          <el-input v-model="countryIntro.numberOfPieces" class="input-width"></el-input>
        </el-form-item>
        <el-form-item label="数量：" prop="number">
          <el-input v-model="countryIntro.number" class="input-width"></el-input>
        </el-form-item>
        <el-form-item label="法定数量：" prop="legalNumber">
          <el-input v-model="countryIntro.legalNumber" class="input-width"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-if="!countryIntro.id" type="primary" @click="onSubmit()" :disabled="disabledbtn">提交新增</el-button>
          <el-button v-else type="primary" @click="updateSubmit()" :disabled="disabledbtn">提交修改</el-button>
          <!-- <el-button v-if="!isEdit" @click="resetForm('countryIntroFrom')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import http from '../../../api/http'
import { getStore } from '../../../tools/storage'

// 分页查询仓储信息
const warehouseInformationList = (data) => {
  return http.get('/mall-merchant/warehouseInformation/list', data)
}

// 分页查询仓库信息
const warehouseList = (data) => {
  return http.get('/mall-merchant/warehouse/list', data)
}
// 保存仓储信息
const saveStorageInformation = (data) => {
  return http.post('/mall-merchant/warehouseInformation/saveStorageInformation', data, true)
}
// 修改仓储信息
const updatewarehouseInformation = (data) => {
  return http.post('/mall-merchant/warehouseInformation/update', data, true)
}
export default {
  name: 'logisticsMent',
  data () {
    return {
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        memberId: null,
        warehouseId: null,
        storageStartDate: null,
        warehousingEndDate: null
      },
      list: [],
      total: 0,
      listLoading: false,

      dialogVisible: false,
      disabledbtn: false,
      rules: {
        warehouseId: [
          { required: true, message: '请选择仓库', trigger: 'blur' }
        ],
        noticeTime: [
          { required: true, message: '请选择通知时间', trigger: 'blur' }
        ],
        expectedReceiptTime: [
          { required: true, message: '请选择期望入库时间', trigger: 'blur' }
        ],
        receiptNumber: [
          { required: true, message: '请输入收货单号', trigger: 'blur' }
        ],
        numberOfPieces: [
          { required: true, message: '请输入件数', trigger: 'blur' }
        ],
        legalNumber: [
          { required: true, message: '请输入法定数量', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '请输入数量', trigger: 'blur' }
        ]
      },
      warehouseQuery: {
        pageNum: 1,
        pageSize: 100
      },
      countryIntro: {
        productName: '',
        receiptNumber: '',
        numberOfPieces: '',
        number: '',
        legalNumber: '',
        noticeTime: '',
        expectedReceiptTime: ''
      },
      warehouseoptions: [],
      warehouselistLoading: false,
      useroptions: [],
      userlistLoading: false
    }
  },
  components: {},
  methods: {
    getDataList () {
      this.listLoading = true
      const memberId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')).id : null
      this.listQuery.memberId = memberId
      warehouseInformationList(this.listQuery).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          this.list = res.data.data.records
          this.total = parseInt(res.data.data.total)
        }
        this.listLoading = false
      }).catch((err) => {
        this.listLoading = false
        console.log(err)
      })
    },
    getwarehouseDataList () { // 查仓库
      this.warehouselistLoading = true
      warehouseList(this.warehouseQuery).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          this.warehouseoptions = res.data.data.records
          this.warehouselistLoading = false
          this.dialogVisible = true
        }
      }).catch((err) => {
        this.warehouselistLoading = false
        console.log(err)
      })
    },
    warehouseName (val) { // 仓库名称
      // console.log(val);
      for (const i of this.warehouseoptions) {
        if (i.id === val) {
          this.countryIntro.warehouseName = i.name
          return
        } continue
      }
    },
    handleSizeChange (val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getDataList()
    },
    handleCurrentChange (val) {
      this.listQuery.pageNum = val
      this.getDataList()
    },
    async addbtn () { // 新增打开弹框
      this.getwarehouseDataList()
    },
    onSubmit () { // 提交新增
      const user = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null
      this.countryIntro.memberId = user.id
      this.countryIntro.member = user.realName
      this.disabledbtn = true
      console.log(this.countryIntro)

      saveStorageInformation(JSON.parse(JSON.stringify(this.countryIntro))).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.countryIntro = {
            productName: '',
            receiptNumber: '',
            numberOfPieces: '',
            number: '',
            legalNumber: '',
            noticeTime: '',
            expectedReceiptTime: ''
          }
          this.dialogVisible = false
          this.listQuery.pageNum = 1
          this.getDataList()
        } else {
          ElMessage.warning({
            message: res.data.msg
          })
        }
        this.disabledbtn = false
      }).catch((err) => {
        console.log(err)
        this.disabledbtn = false
      })
    },
    addOrUpdateHandle (row) { // 修改按钮
      console.log(row)
      this.countryIntro = row
      // this.dialogVisible = true
      this.getwarehouseDataList()
    },
    updateSubmit () { // 提交修改
      console.log(this.countryIntro)
      if (this.countryIntro.auditStatus === 2) {
        this.countryIntro.auditStatus = 0
      }
      this.disabledbtn = true
      updatewarehouseInformation(JSON.parse(JSON.stringify(this.countryIntro))).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.dialogVisible = false
          this.listQuery.pageNum = 1
          this.getDataList()
        } else {
          ElMessage.warning({
            message: res.data.msg
          })
        }
        this.disabledbtn = false
      }).catch((err) => {
        console.log(err)
        this.disabledbtn = false
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.countryIntro = {}
          done()
        })
        .catch(() => {})
    }
  },
  mounted () {
    this.getDataList()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.war_body{
  .addbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .pagination-container{
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .input-width {
    width: 70%;
  }
}

</style>
