<template>
  <div class="right-form">
    <!-- <div style="padding: 10px; cursor: pointer">
      <el-page-header @back="toBack" content="竞拍发布"> </el-page-header>
    </div> -->
    <el-form
      label-position="right"
      :model="smsAuction"
      ref="smsAuction"
      :rules="rules"
      label-width="100px"
    >
      <el-card class="form-card card-one">
        <!-- <el-row :gutter="50" type="flex">
          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Settlement_area')" prop="areaName">
              <el-select
                v-model="smsAuction.areaName"
                :placeholder="$t('m.merchantworkbench.Please_select')"
                class="selectInput"
                value-key="id"
                @change="getSite"
                style="width: 150px"
              >
                <el-option
                  v-for="item in siteArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Choose_spot_or_pre_sale')" label-width="120px">
              <el-radio-group
                v-model="previewStatus"
                style="width: 200px"
                @change="getProduct"
              >
                <el-radio :label="0">现货商品</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="50" type="flex">
          <el-col :span="8">
            <el-form-item :label="$t('m.personalcenter_myorder.detail_product_name')" prop="name">
              <el-select
                v-model="smsAuction.name"
                :placeholder="$t('m.merchantworkbench.Please_select_a')"
                class="selectInput"
                @visible-change="showProduct"
                @change="getPre"
              >
                <el-option
                  v-for="item in pdlist"
                  :key="item.id"
                  :label="item.name+'-'+item.productSn"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item :label="$t('m.personalcenter_myorder.specification')" required>
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.personalcenter_myorder.specification')"
                maxlength="10"
                v-model="jhguige"
                @change="chooseSpData"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
            <el-form-item label="质量标准" prop="name">
              <el-select
                size="medium"
                placeholder="请选择质量标准"
                class="selectInput"
                v-model="smsAuction.qualityStandard"
                @change="getqualityStandard"
              >
                <el-option
                  v-for="item in qualityStandardArr"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="8" v-if="previewStatus == 1">
            <el-form-item :label="$t('m.merchantworkbench.Delivery_time')" prop="jhTime">
              <el-date-picker
                class="selectInput"
                v-model="smsAuction.jhTime"
                type="date"
                value-format="YYYY-MM-DD"
                @change="chooseSpData"
                :placeholder="$t('m.merchantworkbench.option_date')"
                :disabledDate="disabledDate"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Unit_of_measurement')">
                <!-- <el-select v-model="unitOfMeasurement_value">
                  <el-option
                    v-for="item in unitOfMeasurement_option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
                <el-input
                  :placeholder="$t('m.merchantworkbench.Unit_of_measurement')"
                  maxlength="10"
                  v-model="unitOfMeasurement_value"
                ></el-input>
              </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="form-card card-one">
        <div class="gbTitle">{{$t('m.merchantworkbench.Bidding_rules')}}</div>
        <!-- 规则 -->
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Auction_title')" label-width="120px">
              <el-input maxlength="10"
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.merchantworkbench.Auction_title')"
                v-model="smsAuction.title"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('m.auction.Auction_mode')" label-width="120px">
              <el-select v-model="smsAuction.type" :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.auction.Auction_mode')"
                  @change="selectMoudle">
                <el-option
                  v-for="item in typeArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Total_number_of_bids')" prop="allNums">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.merchantworkbench.Total_number_of_bids')"
                v-model="smsAuction.allNums"
                maxlength="8"
                @change="isToNum('zl',smsAuction.allNums)"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
            <el-form-item label="出价方式">
              <el-select
                v-model="smsAuction.outWay"
                placeholder="请选择出价方式"
                @change="showCjfs"
              >
                <el-option
                  v-for="item in outWayArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="14">
            <!-- multiple -->
            <div v-if="smsAuction.outWay == 1">
              <el-form-item label="定向白名单">
                <el-button
                  type="primary"
                  class="el-icon-setting"
                  style="margin: 0 10px"
                  @click="addWhite"
                  size="mini"
                  >可见性维护</el-button
                >

              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <!-- 回显白名单 -->
        <el-row v-if="newCheckList.length != 0">
          <div style="margin: 10px 0">
            <el-card>
              <div class="clearfix">
                <span style="font-size: 16px">战略申购集合</span>
                <el-button
                  style="float: right; padding: 3px 0"
                  @click="clearNames"
                  type="text"
                  >清空</el-button
                >
              </div>
              <div style="">
                <el-table :data="newCheckList" style="width: 100%">
                  <el-table-column label="序号" type="index" width="60">
                  </el-table-column>
                  <el-table-column prop="name" label="客户" width="180">
                  </el-table-column>
                  <el-table-column prop="num" label="限购数量">
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </div>

          <!-- <div style="font-size: 14px; padding: 10px 0"></div> -->
        </el-row>
        <el-row>
          <!-- 最小起拍量 -->
          <!-- <el-col :span="8">
            <el-form-item :label="$t('m.auction_englishauction.Minimum_purchase_quantity')"
             label-width="120px" prop="minqp">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.auction_englishauction.Minimum_purchase_quantity')"
                v-model="smsAuction.minqp"
                maxlength="8"
                @change="isToNum('qipaiL',smsAuction.minqp)"
                @blur="checkAllNums"
              ></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Starting_unit_price')"
             label-width="120px" prop="price">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.merchantworkbench.Starting_unit_price')"
                maxlength="8"
                @change="isToNum('qipaiJ',smsAuction.price)"
                v-model="smsAuction.price"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 最小加拍量 -->
          <!-- <el-col :span="8">
            <el-form-item :label="$t('m.auction_englishauction.Minimum_increase')" prop="setNums">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.auction_englishauction.Minimum_increase')"
                maxlength="8"
                @change="isToNum('minqipaiL',smsAuction.setNums)"
                v-model="smsAuction.setNums"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8" v-if="smsAuction.type != 1">
            <el-form-item :label="$t('m.merchantworkbench.Minimum_markup_unit')" label-width="120px">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.merchantworkbench.Minimum_markup_unit')"
                maxlength="8"
                @change="isToNum('minqijiaJ',smsAuction.setPrice)"
                v-model="smsAuction.setPrice"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 最低成交比 -->
          <!-- <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Minimum_transaction_ratio')"
             label-width="120px" prop="mincomplete">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.merchantworkbench.Minimum_transaction_ratio')"
                maxlength="3"
                @change="isToNum('minCjb',smsAuction.mincomplete)"
                v-model="smsAuction.mincomplete"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- 定金 -->
          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.earnest_money_ratio')" prop="percent">
              <el-input
                v-model="smsAuction.percent"
                :disabled="previewStatus == 1 ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item :label="$t('m.auction_englishauction.price_cut')+'/'+$t('m.index.yuan')" v-if="smsAuction.type == 1">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.auction_englishauction.price_cut')+'/'+$t('m.index.yuan')"
                maxlength="8"
                @change="isToNum('JJfud',smsAuction.dropprice)"
                v-model="smsAuction.dropprice"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.Markdown_cycle')+'/'+$t('m.auction_englishauction.minute')" v-if="smsAuction.type == 1"
              label-width="120px">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.merchantworkbench.Markdown_cycle')+'/'+$t('m.auction_englishauction.minute')"
                maxlength="8"
                @change="isToNum('JJTime',smsAuction.droptime)"
                v-model="smsAuction.droptime"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('m.merchantworkbench.lowest_price')" v-if="smsAuction.type == 1"
             label-width="120px"
             prop="percent">
              <el-input
                :placeholder="$t('m.merchantworkbench.please_choose')+$t('m.merchantworkbench.lowest_price')"
                maxlength="8"
                @change="isToNum('minPrice',smsAuction.minPrice)"
                v-model="smsAuction.minPrice"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label-width="120px"
              :label="$t('m.merchantworkbench.Auction_period')"
              prop="timeMap"
            >
              <div class="block">
                <el-date-picker
                  v-model="smsAuction.timeMap"
                  @change="getTimeMap"
                  type="datetimerange"
                  :range-separator="$t('m.personalcenter_myorder.reach')"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :start-placeholder="$t('m.personalcenter_myorder.start_date')"
                  :end-placeholder="$t('m.personalcenter_myorder.date_closed')"
                  :disabledDate="disabledDateNow"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <!-- <el-card class="form-card card-one">
        <div class="gbTitle">
          保证金规则
          {{smsAuction.bzjradio}}
        </div>
        <div class="radioCell">
          <el-radio v-model="smsAuction.bzjradio" label="1"
            >不收取保证金</el-radio
          >
        </div>
        <div class="radioCell">
          <el-radio v-model="smsAuction.bzjradio" label="2"
            >收取固定金额保证金</el-radio
          >
          <el-input
            type="text"
            v-model="smsAuction.bzjPrice"
            placeholder="输入保证金"
            class="selectInput"
          ></el-input>
        </div>
        <div class="radioCell">
          <el-radio v-model="smsAuction.bzjradio" label="3"
            >收取首笔出价的金额</el-radio
          >
          <el-input
            type="text"
            :disabled="true"
            placeholder="输入首笔出价金额"
            v-model="smsAuction.percent"
            class="selectInput"
          ></el-input>
        </div>
      </el-card>

      <el-card class="form-card card-one">
        <div class="gbTitle">
          延时规则（竞拍结束前2分钟如有新的出价，则竞拍时间自动延长5分钟）
        </div>
        <div>
          <div class="radioCell">
            <el-radio v-model="smsAuction.openStatus" label="1">启用</el-radio>
          </div>
          <div class="radioCell">
            <el-radio v-model="smsAuction.openStatus" label="2"
              >不启用</el-radio
            >
          </div>
        </div>
      </el-card>

      <el-card class="form-card card-one">
        <div class="gbTitle">
          倒计时规则 (当出价总量大于等于竞拍总量时，15分钟内无新报价则竞拍结束)
        </div>
        <div>
          <div class="radioCell">
            <el-radio v-model="smsAuction.openDjsStatus" label="1"
              >启用</el-radio
            >
          </div>
          <div class="radioCell">
            <el-radio v-model="smsAuction.openDjsStatus" label="2"
              >不启用</el-radio
            >
          </div>
        </div>
      </el-card> -->
    </el-form>
    <div style="padding:40px 0;">
      <div class="card-btn">
        <el-button :loading="submitloading"
        @click="submitForm('smsAuction')"
        >发布</el-button>
      </div>
    </div>

    <el-dialog title="添加白名单" v-model="isShowBmd" width="30%">
      <el-dialog
        width="30%"
        title="战略申购集合1"
        v-model="innerVisible"
        append-to-body
      >
        <div>
          <el-table :data="nameCheckList" style="width: 100%">
            <el-table-column type="index" width="40"> </el-table-column>
            <el-table-column prop="type" label="类型" width="80">
            </el-table-column>
            <el-table-column prop="memberPhone" label="客户" width="180">
            </el-table-column>
            <el-table-column label="限量竞购">
              <template v-slot="scope">
                <div>
                  <el-input
                    v-model="scope.row.num"
                    size="mini"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 20px; text-align: center">
            <el-button type="warning" @click="confrimInsert()">确定</el-button>
          </div>
        </div>
      </el-dialog>
      <div>
        <div class="cell_items">
          <!-- @change="getGroupId" -->
          <!-- multiple  -->
          <span
            >选择客户组：<el-select
              v-model="whiteGroupVal"
              placeholder="请选择"
              @change="getMygroup"
            >
              <el-option
                v-for="item in whiteGroup"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></span>
        </div>
        <div class="cell_items">
          <!-- {{nameCheckList}} -->
          <div class="radioGroup">
            <!-- <span>请选组内客户：</span> -->

            <div style="color: #666" v-if="nameCheckData.length < 1">
              请先选择客户组
            </div>
            <el-checkbox-group
              v-else
              v-model="nameCheckList"
              style="width: auto; text-align: left"
              @change="getName"
            >
              <el-checkbox
                v-for="(item, index) in nameCheckData"
                :label="item"
                :key="index"
                >{{ item.memberPhone }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="cell_items">
          已选择客户：<span>{{ nameCheckList.length }} 个</span>
        </div>
      </div>
      <span class="dialog-footer">
        <el-button @click="isShowBmd = false">取 消</el-button>
        <el-button type="primary" @click="addWhiteHadel">确认添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
// import { smsWhiteList, getSmsWhiteList } from '@/api/index.js'
import { ElMessage } from 'element-plus'
import http from '../../../api/http'
import { getCurrentInstance, ref } from 'vue-demi'
// // 站点
// const selectList = (data) => {
//   return http.get('/mall-merchant/pmsSite/selectList', data)
// }
// 交收区域
const selectListqy = (data) => {
  return http.get('/mall-portal/pmsSite/selectList', data)
}
// // 商品 // 查可发布竞拍的商品(旧)
// const selectListPd = (data) => {
//   return http.get('/mall-merchant/pmsSiteProduct/selectList', data)
// }
// 竞拍发布查询商品
const auctionSelectList = (data) => {
  return http.get('/mall-merchant/product/auctionSelectList', data)
}
// 发布竞拍
const subAuction = (data) => {
  return http.post('mall-buyer/auction/sub', data, true)
}
// 白名单
const smsWhiteList = (data) => {
  return http.get('mall-merchant/smsWhiteGroup/getList', data)
}
// 白名单人员列表
const getSmsWhiteList = (data) => {
  return http.get('mall-merchant/smsWhiteList/getList', data)
}

export default {
  data () {
    return {
      spData: [], // 规格数据
      siteArr: '',
      selectedArr: '', // 选中的交收区域
      pmsProductPresaleExtraEntities: ref({}), // 提前天数
      jhTime: '', // 交货日期
      jhguige: '', // 规格
      smsAuction: {
        productId: '',
        siteName: '',
        areaName: '',
        name: '', // 商品名称
        bzjradio: '',
        bzjPrice: '',
        firstPrice: '',
        openStatus: '',
        openDjsStatus: '',
        title: '',
        percent: 0,
        type: 0,
        allNums: '',
        outWay: 0,
        minqp: '',
        price: '',
        setNums: '',
        setPrice: '',
        datetime: '',
        mincomplete: '',
        timeMap: ref(''),
        whiteNames: '',
        droptime: '', // 降价周期/分钟 比如:5
        dropprice: '', // 降价幅度/元 比如:5
        minPrice: '', // 最低价
        qualityStandard: ''
      },
      qualityStandardArr: [
        {
          label: '一类',
          value: 1
        },
        {
          label: '二类',
          value: 2
        },
        {
          label: '三类',
          value: 3
        }
      ],
      prouctInfo: '',
      previewStatus: 0,

      pickerOptions: {
        disabledDate (time) {
          time = moment(time).format('YYYY-MM-DD')
          return time === moment(new Date()).add(1, 'd').format('YYYY-MM-DD')
        }
      },
      pdlist: [],
      outWayArr: [
        {
          value: 0,
          label: '公开竞价'
        },
        {
          value: 1,
          label: '封闭出价'
          // 合作申购
        }
      ],
      typeArr: [
        // {
        //   value: 1,
        //   label: '荷兰式竞拍'
        // },
        {
          value: 0,
          label: '英式竞拍'
        }
      ],
      unitOfMeasurement_option: [{ value: '吨', label: '吨' }, { value: 'KG', label: 'KG' }], // 所有单位
      unitOfMeasurement_value: '', // 已选单位  // 计量单位
      checkedlist: [],
      whiteGroup: [],
      whiteGroupVal: [],
      isShowBmd: false,
      nameCheckList: [],
      nameCheckData: [],
      rules: {
        areaName: [
          { required: true, message: '请选择交收区域', trigger: 'change' }
        ],
        siteName: [
          { required: true, message: '请选择站点', trigger: 'change' }
        ],
        name: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],

        // jhguige: [{ required: true, message: '请填写规格', trigger: 'blur' }],
        jhTime: [
          { required: true, message: '请选择交货时间', trigger: 'change' }
        ],
        offerForm: [
          { required: true, message: '请选择报价方式', trigger: 'change' }
        ],
        price: [{ required: true, message: '请填写售价金额', trigger: 'blur' }],
        currencySymbol: [
          { required: true, message: '请选择币种', trigger: 'change' }
        ],
        timeMap: [
          { required: true, message: '请选择竞拍时段', trigger: 'change' }
        ],
        payWay: [
          { required: true, message: '请选择支付方式', trigger: 'change' }
        ],
        minBuy: [
          { required: true, message: '请填写最小订货量', trigger: 'blur' }
        ],
        minIncr: [
          { required: true, message: '请填写最小增量', trigger: 'blur' }
        ],
        offerExpiryDate: [
          { required: true, message: '请选择报价有效期', trigger: 'change' }
        ],

        // 竞价规则
        allNums: [
          { required: true, message: '请填写竞拍总量', trigger: 'blur' }
        ],
        minqp: [{ required: true, message: '请填写起拍量', trigger: 'blur' }],
        // price: [{ required: true, message: '请填写起拍单价', trigger: 'blur' }],
        setNums: [
          { required: true, message: '请填写最小加拍量', trigger: 'blur' }
        ],
        mincomplete: [
          { required: true, message: '请填写最低成交比', trigger: 'blur' }
        ],
        percent: [{ required: true, message: '请填写定金', trigger: 'blur' }]
      },

      shenGouGroup: [],
      innerVisible: false,
      newCheckList: [],
      isWhDisabled: true,
      productPic: '',
      submitloading: ref(false) // 按钮的loading效果
    }
  },
  created () {
    this.getInitData()
  },
  computed: {
    defaulTime () {
      const nowTime = moment().format('HH:mm:ss') // 获取时分秒
      const addTime = moment().add(1, 'minutes').format('HH:mm:ss')
      const defaultTimes = [addTime, nowTime]
      return defaultTimes
    }
  },

  methods: {
    getqualityStandard (e) {
      console.log(e)
    },
    selectMoudle (e) {
      // console.log(e)
      this.smsAuction.dropprice = ''
      this.smsAuction.price = ''
      this.smsAuction.minPrice = ''
    },
    checkAllNums () {
      console.log(this.smsAuction.allNums)
      console.log(this.smsAuction.minqp)
      if (Number(this.smsAuction.minqp) > Number(this.smsAuction.allNums)) {
        console.log('true')
        ElMessage.warning('起拍数量不得大于当前竞拍总量')
        this.smsAuction.minqp = 0
      }
    },
    clearNames () {
      this.newCheckList = []
    },
    getMygroup (e) {
      this.whiteGroupVal = e
      this.nameCheckList = []
      console.log(this.whiteGroupVal)
      this.getSmsWhiteList()
    },
    showCjfs () {
      console.log('123')
      this.getWhiteList()
    },
    confrimInsert () {
      this.newCheckList = []
      console.log('aaa', this.nameCheckList)
      for (var i in this.nameCheckList) {
        this.newCheckList.push({
          id: this.nameCheckList[i].id,
          num: this.nameCheckList[i].num,
          name: this.nameCheckList[i].memberPhone
        })
      }
      console.log(this.newCheckList)
      this.nameCheckList = []
      this.innerVisible = false
      this.isShowBmd = false
    },
    // 确认添加白名单
    addWhiteHadel () {
      this.innerVisible = true

      console.log()
    },
    getName (item) {
      console.log('item', item)
      this.shenGouGroup = []

      console.log(this.shenGouGroup)
    },
    getGroupId (e) {
      console.log(e)
    },
    async getSmsWhiteList () {
      const data = {
        pageNum: 1,
        pageSize: 100,
        groupId: this.whiteGroupVal
      }
      const res = await getSmsWhiteList(data)
      if (res.data.code === 200) {
        this.nameCheckData = res.data.data.records
        console.log('百名dry', res.data.data)
        // this.total = res.data.data.total;
      } else {
        // loading.close()
        ElMessage.error(res.data.msg)
      }
    },
    async getWhiteList () {
      this.whiteGroup = []
      const data = {
        pageNum: 1,
        pageSize: 100
      }
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading'
      })
      const res = await smsWhiteList(data)
      console.log('res', res.data)
      if (res.data.code === 200) {
        loading.close()
        this.whiteList = res.data.data.records
        for (var i in res.data.data.records) {
          this.whiteGroup.push({
            label: res.data.data.records[i].name,
            value: res.data.data.records[i].id
          })
        }
        // this.total = res.data.data.total;
      } else {
        loading.close()
        ElMessage.error(res.data.msg)
      }
    },
    addWhite () {
      this.isShowBmd = true
      this.nameCheckData = []
      this.getSmsWhiteList()
    },
    // 所有初始化数据
    getInitData () {
      this.getSiteData()
    },
    // 获取站点，区域
    async getSiteData () {
      // const data = {
      //   pageNum: 1,
      //   pageSize: 100,
      //   countryCode: 3702,
      //   type: null
      // }
      // const res = await selectList(data)
      // if (res.data.code === 200) {
      //   this.siteArr = res.data.data
      // } else {
      //   ElMessage({
      //     type: 'error',
      //     message: res.data.message
      //   })
      // }
      const resqy = await selectListqy({
        pageNum: 50,
        pageSize: 1
      })
      if (resqy.data.code === 200) {
        this.siteArr = resqy.data.data
      }
      console.log(resqy.data)

      // console.log('site:', res.data)
    },
    getSiteID () {
      console.log('sited', this.smsAuction.siteName)
    },
    getProduct () {
      this.smsAuction.name = ''
      this.smsAuction.percent = ''
      this.getProudct()
    },
    showProduct (item) {
      // console.log(item)

      // console.log(this.smsAuction.siteName, this.previewStatus)
      // if(this.smsAuction.siteName!=''&& this.previewStatus!=''){
      //   console.log('222')

      // }
      this.getProudct()
    },
    // 商品信息
    async getProudct (siteId) {
      const data = {
        publishStatus: 1,
        verifyStatus: 1,
        pageSize: 100
      }
      // const res = await selectListPd(data)
      const res = await auctionSelectList(data)
      console.log(res)
      if (res.data.code === 200) {
        this.pdlist = res.data.data
      }
      // console.log('pdlist', res.data)
    },
    getPre (e) {
      console.log(e)
      this.smsAuction.jhTime = ''
      this.productPic = e.pic
      this.smsAuction.name = e.name
      this.smsAuction.productId = e.id
      if (this.previewStatus === 0) {

      } else {
        this.pmsProductPresaleExtraEntities = e.pmsProductPresaleExtraEntities[0]
      }
      // console.log(this.smsAuction.productId)
      if (this.previewStatus === 1) {
        this.smsAuction.percent = e.prePayRate
        // console.log(this.smsAuction.percent)
      }
    },
    // 发布成功之后所做的处理
    toBack () {
      window.location.reload() // 页面重新加载
      // this.$emit('backNext', '')
    },
    getSite (data) {
      this.smsAuction.name = ''
      this.selectedArr = data
      this.smsAuction.siteName = this.selectedArr.id
      console.log(data)
    },
    submitForm (formName) {
      this.submitloading = true
      this.$refs[formName].validate((valid) => {
        console.log('smsAuction', this.smsAuction)
        if (this.smsAuction.outWay === 1 && this.newCheckList.length === 0) {
          ElMessage.warning('定向白名单不能为空')
          this.submitloading = false
          return
        }
        if (!this.jhguige) {
          ElMessage.warning('规格不能为空')
          this.submitloading = false
          return
        }
        // return
        if (valid) {
          this.checkedlist.push({
            percent: this.smsAuction.percent,
            expect: this.smsAuction.mincomplete / 100,
            startTime: this.smsAuction.timeMap[0],
            endTime: this.smsAuction.timeMap[1],
            type: this.smsAuction.type,
            unit: this.unitOfMeasurement_value,
            productId: this.smsAuction.productId,
            cycle: Number(this.smsAuction.droptime) * 60000, // 降价周期
            downPrice: this.smsAuction.dropprice, // 降价幅度
            productPic: this.productPic,
            productName: this.smsAuction.name,
            name: this.smsAuction.title, // 标题
            productBrand: this.pdlist[0].brand,
            deliveryPlace: this.smsAuction.siteName,
            purpose: this.smsAuction.outWay,
            whiteListMap: this.newCheckList,
            smsAuctionSku: {
              id: null,
              name: null,
              spData: JSON.stringify(this.spData), // 交货时间
              startPrice: this.smsAuction.price,
              stock: this.smsAuction.allNums,
              minqp: Number(this.smsAuction.minqp), // 最小起拍量
              minjp: Number(this.smsAuction.setNums), // 最小加拍量
              minqj: Number(this.smsAuction.setPrice), // 最小加价单位

              minPrice: Number(this.smsAuction.minPrice), // 最低价 荷兰式

              realPrice: this.smsAuction.price,
              percent: Number(this.smsAuction.percent),
              expect: Number(this.smsAuction.mincomplete / 100),
              startTime: this.smsAuction.timeMap[0],
              endTime: this.smsAuction.timeMap[1],
              type: this.smsAuction.type,
              unit: this.pdlist[0].unit,
              productId: this.smsAuction.productId,
              jz: this.smsAuction.droptime, //
              cycle: Number(this.smsAuction.droptime) * 60000,
              downPrice: this.smsAuction.dropprice,
              newdate: Date.now()
            }
          })
          console.log(this.checkedlist)

          // alert("submit!");
          // 判断是英式竞拍或荷兰式竞拍
          if (this.smsAuction.type === 1) {
            // 荷兰式竞拍竞拍
            if (
              this.smsAuction.dropprice === '' &&
              this.smsAuction.droptime === ''
            ) {
              ElMessage({
                type: 'warning',
                message: '降价幅度或降价周期未填写'
              })
              this.submitloading = false
              return false
            }
            if (this.smsAuction.minPrice === '' && this.smsAuction.minPrice >= this.smsAuction.price) {
              ElMessage({
                type: 'warning',
                message: '最低价不为空且不低于起拍单价'
              })
              this.submitloading = false
              return false
            }
          } else {
            // 英式竞拍竞拍
            if (this.smsAuction.setPrice === '') {
              ElMessage({
                type: 'warning',
                message: '最小加价单位未填写'
              })
              this.submitloading = false
              return false
            }
          }
          this.sendAuctionData()
        } else {
          ElMessage.error({
            message: '发布失败'
          })
          this.submitloading = false
          return false
        }
      })
    },
    getTimeMap (e) {
      console.log(e)
      console.log(this.smsAuction.jhTime)
      const start = e[0]
      const end = e[1]
      if (moment(end).valueOf() <= moment(new Date()).valueOf()) {
        ElMessage.error({
          message: '请选择正确的结束时间!'
        })
        this.smsAuction.timeMap = []
        return ''
      } else if (moment(end).valueOf() - 60000 <= moment(start).valueOf()) {
        ElMessage.error({
          message: '竞拍时长不得低于一分钟!'
        })
        this.smsAuction.timeMap = []
        return ''
      } else if (moment(end).isAfter(this.smsAuction.jhTime) || moment(end).isSame(this.smsAuction.jhTime)) {
        ElMessage.error({
          message: '请在交货时间之前选择拍卖时间'
        })
        this.smsAuction.timeMap = []
        return ''
      }
    },
    chooseSpData (e) {
      this.spData = []
      // this.spData = JSON.parse(this.spData)
      this.spData.push({
        key: '交货日期',
        value: this.smsAuction.jhTime
      }, {
        key: '规格',
        value: this.jhguige || '无'
      })
      // console.log("spData", spData);
      // this.smsAuction.jhTime = spData
    },
    // 判断是不是数字
    isToNum (e, status) {
      console.log(e)
      console.log(status)
      if (this.smsAuction.type === 1) {
        if (e === 'JJfud') {
          this.smsAuction.dropprice = (parseInt(this.smsAuction.dropprice * 100) / 100).toFixed(1)
          if (!/^(0|[1-9]\d*)(\.\d{0,1})?/.test(status)) {
            this.smsAuction.dropprice = '' // 降价幅度
            console.log(this.smsAuction.dropprice)
            // return
          }
          console.log(this.smsAuction.dropprice)
          return
        }
        if (e === 'minPrice') {
          this.smsAuction.minPrice = (parseInt(this.smsAuction.minPrice * 100) / 100).toFixed(1)
          if (!/^(0|[1-9]\d*)(\.\d{0,1})?/.test(status)) {
            this.smsAuction.minPrice = '' // 最低价
            // console.log(this.smsAuction.minPrice)
          }
          return
        }
        if (e === 'qipaiJ') {
          this.smsAuction.price = (parseInt(this.smsAuction.price * 100) / 100).toFixed(1)
          if (!/^(0|[1-9]\d*)(\.\d{0,1})?/.test(status)) {
            this.smsAuction.price = '' // 起拍价
            console.log(this.smsAuction.price)
            // return
          }
          console.log(this.smsAuction.price)
          return
        }
        if (!/^[1-9]\d*$/.test(status)) {
          ElMessage.error({ message: '请输入正确的数字' })
          // e = ''
          switch (e) {
            case 'zl': // 总量
              this.smsAuction.allNums = ''
              break
            case 'qipaiL': // 起拍量
              this.smsAuction.minqp = ''
              break
            case 'minqipaiL': // 最小加拍量
              this.smsAuction.setNums = ''
              break
            case 'minqijiaJ': // 最小加价单位
              this.smsAuction.setPrice = ''
              break
            case 'minCjb': // 最小起成交量
              this.smsAuction.mincomplete = ''
              break
            case 'JJTime': // 降价周期
              this.smsAuction.droptime = ''
              break
            case 'minPrice': // 最低价
              this.smsAuction.minPrice = ''
              break
            default:
              break
          }
        }
      } else {
        if (!/^[1-9]\d*$/.test(status)) {
          ElMessage.error({ message: '请输入正确的数字' })
          // e = ''
          switch (e) {
            case 'zl': // 总量
              this.smsAuction.allNums = ''
              break
            case 'qipaiL': // 起拍量
              this.smsAuction.minqp = ''
              break
            case 'minqipaiL': // 最小加拍量
              this.smsAuction.setNums = ''
              break
            case 'minqijiaJ': // 最小加价单位
              this.smsAuction.setPrice = ''
              break
            case 'minCjb': // 最小起成交量
              this.smsAuction.mincomplete = ''
              break
            case 'JJfud': // 降价幅度
              this.smsAuction.dropprice = ''
              break
            case 'qipaiJ': //  起拍价
              this.smsAuction.price = ''
              break
            case 'JJTime': // 降价周期
              this.smsAuction.droptime = ''
              break
            case 'minPrice': // 最低价
              this.smsAuction.minPrice = ''
              break
            default:
              break
          }
        }
      }
    },

    // 发布竞拍
    async sendAuctionData () {
      console.log('开始发布')
      console.log('checkedlist', this.checkedlist)
      const loading = this.$loading({
        lock: true,
        text: '发布中',
        spinner: 'el-icon-loading'
      })
      const res = await subAuction(this.checkedlist)

      console.log(res)
      if (res.data.code === 200) {
        ElMessage({
          message: '发布成功',
          type: 'success'
        })
        this.submitloading = false
        loading.close()
        this.checkedlist = []
        // this.toBack()
      } else {
        this.checkedlist = []
        this.submitloading = false
        loading.close()
        ElMessage({
          message: '发布失败' + res.data.msg,
          type: 'error'
        })
      }
    }
  },
  setup () {
    const datacop = getCurrentInstance()
    const disabledDate = (time) => {
      // 此条为设置禁止用户选择今天之前的日期，不包含今天。
      time = moment(time).format('YYYY-MM-DD')
      // const beginDate = datacop.data.pmsProductPresaleExtraEntities.advancePikegoodsDaysBegin
      // const endDate = datacop.data.pmsProductPresaleExtraEntities.advancePikegoodsDaysEnd
      // const bd = moment(new Date()).add(beginDate, 'd').format('YYYY-MM-DD')
      // const ed = moment(new Date()).add(endDate, 'd').format('YYYY-MM-DD')
      // return !moment(time).isBetween(bd, ed, null, '[]')

      const endDate = datacop.data.pmsProductPresaleExtraEntities.advancePikegoodsDaysEnd
      const ed = moment(new Date()).add(endDate + 1, 'd').format('YYYY-MM-DD')
      return moment(time).isBefore(ed)

      // return time.getTime() < Date.now() - (24 * 60 * 60 * 1000)
    }
    const disabledDateNow = (time) => {
      // 此条为设置禁止用户选择今天之前的日期，不包含今天。
      // return time.getTime() < moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
      //  && time.getTime() < moment
      return time.getTime() < Date.now() - (24 * 60 * 60 * 1000)
    }
    return {
      disabledDate, disabledDateNow
    }
  }
}
</script>

<style lang="scss">
.right-form {
  background: transparent;
}
.right-form .selectInput {
  width: 180px;
}
.gbTitle {
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
}
.radioCell {
  text-align: left;
  margin-bottom: 10px;
}
.cell_items {
  text-align: left;
  margin-bottom: 10px;
}
.radioGroup {
  /* display: flex; */
  margin-top: 10px;
}
.publish {
  color: #fff;
  border: 1px solid #409eff;
  background: #409eff;
}
.card-btn{
  display: flex;
  justify-content: center;
  .el-button{
    width: 400px;
    background:#C6824E;
    border-color: #C6824E;
    color: #fff;
  }
}
</style>
