<template>
  <div class="order_man">
    <div class="order_sear">
      <el-card class="filter-container" shadow="never">
        <template #header>
          <span>筛选搜索</span>
        </template>
        <div class="selectname">
          <el-input
            size="small"
            v-model="orderQueryList.orderSn"
            placeholder="请输入订单编号"
          >
            <template #append>
              <el-button :icon="Search" @click="clickInputOrderNum()" />
            </template>
          </el-input>
        </div>
        <div class="selectname">
          <el-select v-model="fenleiValue" filterable clearable placeholder="分类选择" size="small"
          @change="fenleichange">
            <el-option
              v-for="item in fenleioptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-card>
    </div>
    <div class="order_menu">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
        v-for="(i,k) in panList" :key="k"
        :label="i.label+`${labelStatus[k]!=0?` (${labelStatus[k]})`:''}`"
        :name="i.name"
        >
          <component :is="activeName"
          @olistData="()=>{orderListShow}">
          </component>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="table_msg">

      <div class="order_body">
        <tr>
          <th v-for="i in alltitle" :key="i">
            {{i}}
          </th>
        </tr>
        <tr>
          <div class="content">
            <ul class="order_body_ul"
            v-for="(i,index) in dataList" :key="index">
              <li class="order_body_li body_num1">
                <div class="namebody">
                  <!-- <p>{{i.orderSn}}</p> -->
                  <el-tooltip
                    effect="light"
                    :content="i.orderSn"
                    placement="top"
                  >
                  <p>{{i.orderSn}}</p>
                  </el-tooltip>
                </div>
              </li>
              <li class="order_body_li body_num2">
                <span class="li2_span_kg">{{
                  i.pmsProduct.name
                  }}</span>
                  <!-- <p>规格1</p> -->
              </li>
              <li class="order_body_li body_num3">
                <p class="name_phone_p">{{
                  i.omsOrderItem.productQuantity-i.resaleNum
                  }}</p>
              </li>
              <li class="order_body_li body_num3">
                <p class="name_phone_p">{{
                  i.pmsProduct.currencySymbol+i.omsOrderItem.productPrice
                  }}</p>
              </li>
              <li class="order_body_li body_num4">
                <span class="money_span">{{
                  i.memberUsername.replace(i.memberUsername.substring(3,7), "****")
                  }}</span>
              </li>
              <li class="order_body_li body_num5">
                <span>{{i.pmsProduct.currencySymbol+i.payAmount}}</span>
              </li>
              <li class="order_body_li body_num6">
                <span>{{
                 i.status == 0
                ? "待付款"
                : i.status == 1
                ? "已付款"
                : i.status == 5
                ? "待发货"
                : i.status == 2
                ? "卖家待备货"
                : i.status == 3
                ? "待确认备货"
                : i.status == 12
                ? "待付尾款"
                : i.status == 8
                ? "待确认发票"
                : i.status == 4
                ? "拒绝确认备货"
                : i.status == 6
                ? "待收货"
                : i.status == 7
                ? "已收货"
                : i.status == 9
                ? "已完成"
                : i.status == 10
                ? "已取消"
                : i.status == 11
                ? "订单失败"
                : i.status == 15
                ? "预订成功"
                : i.status == 16
                ? "预订失败"
                : i.status == 17
                ? "待确认"
                : i.status == 30
                ? "待付款-预提"
                : i.status == 31
                ? "待付款-待预提"
                : i.status == 32
                ? "已付款-待预提"
                : i.status == 33
                ? "卖家待确认预提"
                : i.status == 34
                ? "待确认预提"
                : i.status == 40
                ? "已转售"
                : ""}}</span>
                <span
                  v-if="i.orderType == 10"
                  class="exchange"
                >（样品订单）</span>
              </li>
              <li class="order_body_li body_num7">
                <span
                  v-if="i.status == 2"
                  @click.stop="confirmbh(i, index)"
                  class="exchange btn"
                >
                  去备货</span>
                <!-- <el-tag
                type="warning"
                effect="plain"
                v-if="i.status == 8"
                @click.stop="confirmfp(i.orderSn, index)"
                class="exchange btn"
                >确认发票</el-tag> -->
                <span
                  v-if="i.status == 5&&i.orderType != 10"
                  @click="confirmfp(i.orderSn, index, i.takeGoodsType)"
                  class="exchange btn"
                  >去发货</span>
                <!-- <span
                type="warning"
                v-if="i.status == 6 && i.orderType == 4"
                @click.stop="
                  openconfirmshj(i.orderSn, i.orderType, index)
                "
                class="exchange btn"
                >确认收货</span> -->
                <span
                v-if="
                  i.status == 0 || i.status == 31 || i.status == 30
                "
                @click.stop="cancelorder(i.orderSn, index)"
                class="exchange btn"
                >取消订单</span>
                <!-- <el-tag
                  type="warning"
                  v-if="
                    i.status == 0 || i.status == 31 || i.status == 30
                  "
                  @click.stop="confirmpay(i, index)"
                  class="logistics evaluate btn"
                  >付款</el-tag> -->
                <!-- <el-tag
                  type="warning"
                  v-if="i.status == 12"
                  class="logistics btn"
                  >付尾款</el-tag> -->
                <!-- <el-tag
                  type="warning"
                  v-if="i.status == 32"
                  @click.stop="requestadvance(i.orderSn, index)"
                  class="logistics btn"
                  >申请预提</el-tag> -->
                <!-- <el-tag
                  type="warning"
                  v-if="i.status == 34"
                  @click.stop="payyt(i.orderSn, index)"
                  class="logistics btn"
                  >确认预提</el-tag> -->
                <span
                  v-if="i.status == 33"
                  @click.stop="saleyt(i.orderSn, index)"
                  class="logistics btn"
                  >确认预提</span>
                <!-- <el-tag
                  type="warning"
                  v-if="i.status == 15"
                  @click.stop="applyfh(i.orderSn, index)"
                  class="logistics btn"
                  >申请发货</el-tag> -->
                <!-- <el-tag
                  type="warning"
                  v-if="i.status == 17"
                  @click.stop="confirmReturn(i.orderSn, index)"
                  class="logistics btn"
                  >确认不退货</el-tag> -->
              <a class="order_detailcss"
              @click="lookDetails(i)">{{$t('m.merchantworkbench.See_details')}}</a>
              </li>
            </ul>
            <div class="nodata" v-if="dataList.length == 0">
              <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E8%AE%A2%E5%8D%95%E4%B8%BA%E7%A9%BA.png" alt="">
            </div>
          </div>
        </tr>
      </div>

      <!-- 去备货 -->
      <el-dialog v-model="bhshow"
      :close-on-click-modal="false"
       width="30%">
        <div class="pay">
          <el-form label-position="right" label-width="80px">
            <el-form-item label="转售数量" v-if="content.resaleNum >= 1">
              <el-input
                placeholder="请输入转售数量"
                v-model="content.resaleNum"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="备货数量">
              <el-input
                placeholder="请输入备货数量"
                v-model="productQuantitys"
              ></el-input>
            </el-form-item>
            <el-form-item label="物流费" v-if="content.takeGoodsType == 1">
              <el-input
                placeholder="请输入物流费"
                v-model="wl"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传图片">
              <div class="from_geren">
                <ul class="show_img_ul">
                  <li class="show_img_li"
                  v-for="(i,k) in imgList" :key="k">
                    <el-upload
                      list-type="picture"
                      :before-upload="beforeUpload"
                      :on-success="guigeuploadSuccess"
                      :on-preview="handlePictureCardPreview"
                      :data="upLoadData"
                      :action="upLoadUrl"
                      :on-remove="guigehandleRemove"
                      :on-change="guigeonChange"
                      accept="jpg/jpeg/png"
                    >
                    <img @click="imgIndex=k" :src="i.img||'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/unimags.png'" alt="">
                    </el-upload>
                    <span>{{i.msg}}</span>
                  </li>
                </ul>
              </div>
            </el-form-item>
          </el-form>
          <el-button @click="subpays">确认</el-button>
        </div>
      </el-dialog>

      <!-- 去发货 -->
      <el-dialog  v-model="fhshow" width="30%"
      :close-on-click-modal="false">
        <div class="pay">
          <el-form label-position="right" label-width="80px">
            <el-form-item label="快递公司">
              <!-- <el-input
                placeholder="请输入公司名称"
                v-model="couriername"
              ></el-input> -->
              <el-select
                v-model="couriername"
                @change="getCourier"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courierList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="快递单号">
              <el-input
                placeholder="请输入快递单号"
                v-model="couriernumber"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button :disabled="courierdisabled" @click="subcourier"
            >确认</el-button
          >
        </div>
      </el-dialog>

      <!-- 卖家确认预提 33-->
      <el-dialog
        title="确认预提信息"
        v-model="showYt"
        :close-on-click-modal="false"
        width="40%"
      >
        <div class="enterytmsg">
            <div class="textItem">
              <span>提货类型：</span>
              <span class="valueTxt">{{
                  ytData.omsOrder.takeGoodsType == 0
                    ? "自提"
                    : ytData.omsOrder.takeGoodsType == 1
                    ? "物流"
                    : ""
                }}</span>
            </div>
            <div class="textItem">
              <span>{{ytData.omsOrder.takeGoodsType == 1?'收货地址：': '提货地点：'}}</span>
              <span class="valueTxt" v-if="ytData.omsOrder.takeGoodsType == 1">{{
                  ytData.omsOrder.receiverDetailAddress || ""
              }}</span>
              <span class="valueTxt" v-else>{{ytData.takeAddrVOS.name}}</span>
            </div>
            <div class="textItem" v-if="ytData.omsOrder.takeGoodsType == 1">
              <span>收货人(手机号)：</span>
              <span class="valueTxt">{{
                ytData.omsOrder.receiverName +
                  ytData.omsOrder.receiverPhone
              }}</span>
            </div>
            <div class="textItem">
              <span>预提方式：</span>
              <span class="valueTxt"> {{ this.ytWayValue }}{{ytData.pmsProduct.currencySymbol}}{{this.ytPrice}}</span>
            </div>
            <div class="textItem">
              <span>预提数量：</span>
              <span class="valueTxt">
                {{ ytData.omsOrderItem.productQuantity - ytData.omsOrder.resaleNum}}</span
              >
            </div>
            <div class="textItem">
              <span>预提费用：</span>
              <span class="valueTxt">
                {{ytData.pmsProduct.currencySymbol}}{{ (ytData.omsOrderItem.productQuantity - ytData.omsOrder.resaleNum) * this.ytPrice}}</span
              >
            </div>
            <div class="textItem">
              <span>发货时间：</span>
              <span class="valueTxt"> {{ ytData.omsOrder.takeGoodsTime }}</span>
            </div>
            <div class="textItem sfenter">
              <span>是否同意：</span>
              <el-radio-group v-model="isEnter" @change="radioChange">
                <el-radio :label="1">同意</el-radio>
                <el-radio :label="0">驳回</el-radio>
              </el-radio-group>
            </div>
            <div class="textItem" v-if="isEnter == 0">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入驳回理由和建议"
                v-model="rejected"
              >
              </el-input>
            </div>
          <div class="footbtn">
            <el-button
              size="small"
              :disabled="advancedisabled"
              @click="subadvance"
              >提交</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 底部分页 -->
    <div class="showfooter" v-if="totalnum<pagesize?false:true">
      <el-pagination
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        layout="prev, pager, next, jumper"
        :total="totalnum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElLoading } from 'element-plus'
import { Search } from '@element-plus/icons-vue'

import http from '../../../api/http'
import { getCurrentInstance, ref } from 'vue-demi'
import emitter from '../../../tools/bus'

// 取消订单
const cancelOrder = (data) => {
  return http.post('/mall-order/order/cancelOrder', data)
}
// 卖家筛选订单列表
const getOrderListByMemberIdAndStatus = (data) => {
  return http.get('/mall-merchant/order/getOrderListByMemberIdAndStatus', data)
}
// 查分类
const productAttribute = (data) => {
  return http.get('/mall-portal/productCategory/list/withChildren')
}
export default {
  name: 'OrderManagement',
  data () {
    return {
      // panList: [
      //   { label: '全部订单', name: 'allorder' },
      //   { label: '待付款', name: 'obligation' },
      //   { label: '待确认预提', name: 'withholding' },
      //   { label: '待备货', name: 'confirmed' },
      //   { label: '待发货', name: 'pending' },
      //   { label: '已发货', name: 'shipped' },
      //   { label: '交易完成', name: 'completed' },
      //   { label: '已取消', name: 'closeOrder' }
      // ], // 订单状态标题内容
      panList: [
        { label: this.$t('m.personalcenter_myorder.All_Orders'), name: 'allorder' },
        { label: this.$t('m.personalcenter_myorder.Pending_payments'), name: 'obligation' },
        { label: this.$t('m.merchantworkbench.Withholding_to_be_confirmedL'), name: 'withholding' },
        { label: this.$t('m.merchantworkbench.Goods_to_be_prepared'), name: 'confirmed' },
        { label: this.$t('m.merchantworkbench.Goods_to_be_shipped'), name: 'pending' },
        { label: this.$t('m.merchantworkbench.shipped'), name: 'shipped' },
        { label: this.$t('m.merchantworkbench.Deal_Close'), name: 'completed' },
        { label: this.$t('m.merchantworkbench.Canceled'), name: 'closeOrder' }
      ], // 订单状态标题内容
      // 0全部订单 1待付款 2待确认预提 3待备货 4待发货 5已发货 6交易完成 7已取消
      labelStatus: [0, 0, 0, 0, 0, 0, 0, 0],
      activeName: 'allorder', // 当前选择的订单项,
      // alltitle: ['订单编号', '商品信息', '数量', '单价', '买家名称', '实付款', '交易状态', '操作'],
      alltitle: [
        this.$t('m.personalcenter_myorder.order_detail_number'),
        this.$t('m.auction.commodity_information'),
        this.$t('m.auction.quantity'),
        this.$t('m.buy.unit_price'),
        this.$t('m.merchantworkbench.buyer'),
        this.$t('m.paySuccess.Actual_payment'),
        this.$t('m.merchantworkbench.Transaction_Status'),
        this.$t('m.auction.operate')
      ],
      dataList: [],
      statusList: ref(''),

      // 筛选
      // 筛选条件
      fenleioptions: [], // 分类
      fenleiValue: '', // 选中的分类id
      orderQueryList: {
        orderSn: null, // 订单编号
        productCategoryId: null, // 分类id
        // createTime: null, // 创建时间
        // memberUsername: null, // 会员用户名
        // deliverySn: null, // 快递单号
        status: '', // 订单状态
        size: 15,
        page: 1
      },

      // 底部分页
      totalnum: ref(100), // 存放总数据个数
      pagesize: ref(15), // 存放一页显示个数
      currentPage: ref(1), // 默认展示第几页

      // activeName: 'all',
      listLoading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 15,
        orderSn: null,
        receiverKeyword: null,
        status: '',
        orderType: null,
        sourceType: null,
        createTime: null
      },
      searchCont: '',
      list: null,
      total: null,
      operateType: null,

      // 订单列表
      page: 1,
      size: 6,
      types: '',
      activeIndex: '1',

      ukeyBoardStatus: '',
      payAmount: '',
      ordersn: '',
      passwords: '',
      orderType: '',
      paydialogVisible: false,
      confirmfpstatus: false,

      // seller
      allowOverflow: '', // 溢折比例
      numallowOver: '', // 溢折数
      disabled: false,
      productQuantitys: '', // 订单 数
      oldQuantitys: '',
      content: '',
      orderindex: '',
      bhshow: false, // 备货弹窗
      wl: '', // 物流费用
      orderSn: '',
      courierdisabled: false,
      fhshow: false,
      couriername: '', // 当前选择的快递
      couriernumber: '',
      courierList: [], // 物流公司

      // 确认预提
      showYt: false,
      advancedisabled: false, // 按钮是否可点击
      ytData: '',
      ytWayValue: '', // 预提方式
      ytPrice: '', // 预提费用
      ytIndex: '', // 预提订单编号
      isEnter: 1, // 是否同意1同意  0驳回
      rejected: '', // 驳回信息

      // 发货图
      imgList: [
        { img: '', msg: '运单' },
        { img: '', msg: '保单' },
        { img: '', msg: '提单' }
      ],
      // 上传数据
      dir: null,
      fileList: [],
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      }
    }
  },
  methods: {
    // 初始化数量
    initdata () {
      http.get('/mall-merchant/order/getOrderStatusByMerchantId', {
        productCategoryId: this.fenleiValue
      }) // 查询各个状态的数量接口
        .then(res => {
          console.log(res.data.data)
          for (const i in res.data.data) {
            // console.log(i)
            const orders = res.data.data[i].status
            // console.log(typeof orders)
            this.labelStatus[0] += res.data.data[i].quantity
            if (orders === 0 || orders === 12 || orders === 30 || orders === 31) {
              this.labelStatus[1] += res.data.data[i].quantity
            }
            if (orders === 33) {
              this.labelStatus[2] += res.data.data[i].quantity
            }
            if (orders === 2) {
              this.labelStatus[3] += res.data.data[i].quantity
            }
            if (orders === 5) {
              this.labelStatus[4] += res.data.data[i].quantity
            }
            if (orders === 7) {
              this.labelStatus[5] += res.data.data[i].quantity
            }
            if (orders === 9) {
              this.labelStatus[6] += res.data.data[i].quantity
            }
            if (orders === 10) {
              this.labelStatus[7] += res.data.data[i].quantity
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    // 初始化分类列表
    async getProductAttribute () {
      const res = await productAttribute()
      console.log(res)
      if (res.data.code === 200) {
        res.data.data[0].children.forEach(item => {
          this.fenleioptions.push({
            name: item.name,
            id: item.id
          })
        })
      }
    },
    // 切换分类
    fenleichange () {
      console.log(this.fenleiValue)
      this.orderQueryList.productCategoryId = this.fenleiValue
      this.clickInputOrderNum()
      // this.
    },
    // 点击菜单切换的函数
    handleClick (tab, event) {
      console.log(tab.props)
      this.orderListShow(tab.props.name)
    },
    // 切换的状态 调用
    orderListShow (data) {
      this.activeName = data
      switch (data) {
        case 'allorder':
          this.toShowList('', data)
          // emitter.emit('orderDataTo', { showNum: this.totalnum, name: this.activeName, list: this.orderDatashow })
          break
        case 'obligation': this.toShowList('12,0,30,31', data)
          break
        case 'withholding': this.toShowList('33', data)
          break
        case 'confirmed': this.toShowList('2', data)
          break
        case 'pending': this.toShowList('5', data)
          break
        case 'shipped': this.toShowList('7', data)
          break
        case 'completed': this.toShowList('9', data)
          break
        case 'closeOrder': this.toShowList('10', data)
          break
        default:
          break
      }
    },
    // 处理不同状态的切换展示效果
    toShowList (status, name) {
      // console.log(status, name)
      this.statusList = status
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等...'
      })
      // 查询条件
      const getData = {
        orderSn: this.orderQueryList.orderSn,
        status: status,
        size: 15,
        page: this.currentPage
      }
      http.get('/mall-merchant/order/getOrderListByMemberIdAndStatus', getData)
        .then(res => {
          console.log(res)
          this.totalnum = res.data.data.total
          this.dataList = res.data.data.records
          loading.close()
        }).catch(err => {
          console.log(err)
        })
    },
    // 筛选订单编号
    clickInputOrderNum () {
      this.orderQueryList.status = this.statusList
      this.orderQueryList.page = this.currentPage
      console.log(this.orderQueryList)
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等...'
      })
      // if (!this.orderQueryList.orderSn) {
      //   this.labelStatus = [0, 0, 0, 0, 0, 0, 0, 0]
      //   this.initdata()
      // } else {
      //   this.labelStatus = [0, 0, 0, 0, 0, 0, 0, 0] // 显示数量归零
      // }
      this.labelStatus = [0, 0, 0, 0, 0, 0, 0, 0]
      this.initdata()
      getOrderListByMemberIdAndStatus(this.orderQueryList).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          this.totalnum = res.data.data.total
          this.dataList = res.data.data.records
          // this.labelStatus[0] = res.data.data.total

          if (this.orderQueryList.status === '') {
            this.labelStatus[0] = res.data.data.total
          }
        }
        loading.close()
      }).catch((err) => {
        console.log(err)
        loading.close()
      })
    },
    // 查看详情
    lookDetails (data) {
      console.log(data)
      const datadetail = {
        orderSn: data.orderSn,
        orderType: data.orderType
      }
      // emitter.emit('orderdetailData', datadetail)
      emitter.emit('merchantorderdetail', datadetail)
      // this.$router.push(
      //   '/merchantworkbench/detail'
      // )
    },
    // 切换物流
    getCourier (e) {
      console.log(e)
      this.couriername = e
    },
    // 获取物流列表
    getLogistics () {
      try {
        http.get('/mall-merchant/order/getLogisticsNameList')
          .then(res => {
            res.data.data.forEach((item) => {
              const obj = {
                label: item,
                value: item
              }
              this.courierList.push(obj)
            })
          })
        // console.log(this.courierList)
      } catch (e) {
        console.log(e)
      }
    },
    // 备货
    confirmbh (item, index) {
      console.log(item)
      // console.log(index)
      this.disabled = false
      this.allowOverflow = item.pmsProduct.allowOverflow / 100
      // 转售
      if (item.resaleNum > 0) {
        this.productQuantitys = item.omsOrderItem.productQuantity - item.resaleNum
        this.oldQuantitys = item.omsOrderItem.productQuantity - item.resaleNum
        console.log(this.productQuantitys)
        this.numallowOver = this.allowOverflow * this.productQuantitys
      } else {
        this.productQuantitys = item.omsOrderItem.productQuantity
        this.oldQuantitys = item.omsOrderItem.productQuantity
        this.numallowOver = this.allowOverflow * this.productQuantitys
      }

      this.content = item
      this.orderindex = index
      this.bhshow = true
    },
    // 备货数量
    checkNums () {
      // console.log(this.productQuantitys)
      // console.log(this.numallowOver)
      if (/^[1-9]\d*$/.test(this.productQuantitys)) {
        if (this.content.resaleNum > 0) {
          if (this.productQuantitys < this.content.omsOrderItem.productQuantity - this.content.resaleNum - this.numallowOver ||
          this.productQuantitys > this.content.omsOrderItem.productQuantity - this.content.resaleNum + this.numallowOver) {
            ElMessage.error(`需备货幅度不超过${this.numallowOver}`)
            this.productQuantitys = this.content.omsOrderItem.productQuantity - this.content.resaleNum
            return false
          } else {
            return true
          }
        } else {
          if (this.productQuantitys < this.content.omsOrderItem.productQuantity - this.numallowOver ||
          this.productQuantitys > this.content.omsOrderItem.productQuantity + this.numallowOver) {
            ElMessage.error(`需备货幅度不超过${this.numallowOver}`)
            this.productQuantitys = this.content.omsOrderItem.productQuantity
            return false
          } else {
            return true
          }
        }
      } else {
        if (this.content.resaleNum > 0) {
          ElMessage.warning({ message: '请输入正确的数值' })
          this.productQuantitys = this.content.omsOrderItem.productQuantity - this.content.resaleNum
          return false
        }
        ElMessage.warning({ message: '请输入正确的数值' })
        this.productQuantitys = this.content.omsOrderItem.productQuantity
        return false
      }
      // this.productQuantitys = this.content.omsOrderItem.productQuantity
    },
    // 备货图片上传
    beforeUpload (file) { // 上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 20480 // 这里做文件大小限制 // 暂时取消限制
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg格式!',
          type: 'warning'
        })
        return
      }
      if (!isLt2M) { // 暂时取消限制
        ElMessage({
          message: '上传文件大小不能超过20M!',
          type: 'warning'
        })
        return
      }
      const that = this
      const param = {
        prefix: 'beiHuo/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            console.log(res)
            that.dir = res.data.data.dir
            that.upLoadData.key = `${res.data.data.dir}/${file.name}`
            that.upLoadData.policy = res.data.data.policy
            that.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            that.upLoadData.signature = res.data.data.signature
            that.upLoadUrl = res.data.data.host
            // this.formData.specification_listData[this.imgIndex].price = file
            // console.log(that.upLoadData)
            // this.imgList[this.imgIndex].img = this.upLoadUrl + '/' + this.upLoadData.key

            // console.log(this.formData.specification_listData)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    guigeuploadSuccess (res, file) { // 图片改变触发方法
      // console.log(file)
      // this.showFileList = true
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      setTimeout(() => {
        // this.imgList[this.imgIndex].img = data.action + '/' + data.data.key/
        this.imgList[this.imgIndex].img = url
      }, 1000)
      // console.log(this.imgList)
    },
    handlePictureCardPreview (file) { // 图片放大方法
      console.log(file)
      // this.formData.decript.dialogImageUrl = file.url
      // this.formData.decript.dialogVisible = true // 打开弹窗
    },
    guigehandleRemove (file) { // 删除图

    },
    guigeonChange (file, fileList) { // 暂时用不到

    },
    // 备货 提交
    subpays () {
      console.log(this.content.takeGoodsType)
      // return
      const checkNumsStatus = this.checkNums()
      // console.log(aa)
      if (!checkNumsStatus) {
        return
      }
      // console.log(typeof this.wl)
      this.wl = Number(this.wl)
      this.wl = this.wl.toFixed(1)

      if (this.wl < 0 && this.content.takeGoodsType === 1) {
        ElMessage.error({
          message: '请输入物流费'
        })
        return
      }
      if (this.imgList[0].img === '' || this.imgList[1].img === '' || this.imgList[2].img === '') {
        ElMessage.error({
          message: '请完善运单、保单、提单图片'
        })
        this.imgList.forEach(item => {
          item.img = ''
        })
        // console.log(this.imgList)
        return
      }

      let data = ''
      if (this.content.takeGoodsType === 1) {
        // 物流
        data = {
          amount: this.productQuantitys,
          orderSn: this.content.orderSn,
          payable: this.wl,
          waybillPicture: this.imgList[0].img,
          policyPicture: this.imgList[1].img,
          billOfLadingPicture: this.imgList[2].img
        }
      } else if (this.content.takeGoodsType === 0) {
        // 自提
        data = {
          amount: this.productQuantitys,
          orderSn: this.content.orderSn,
          waybillPicture: this.imgList[0].img,
          policyPicture: this.imgList[1].img,
          billOfLadingPicture: this.imgList[2].img
        }
      }
      this.disabled = true

      console.log(data)
      console.log(this.dataList)
      try {
        http.post('mall-merchant/order/sellerStockUp', data)
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
              ElMessage.success({
                message: '备货成功',
                type: 'success'
              })
            } else {
              ElMessage.warning({
                message: res.data.msg,
                type: 'success'
              })
            }
            this.bhshow = false
            // this.$set(this.list[this.orderindex], 'status', 3)
            this.dataList[this.orderindex].status = 3
          }).catch(err => {
            this.disabled = false
            console.log(err)
          })
      } catch (e) {
        console.log(e)
      }
    },
    // 去发货
    subcourier () {
      if (!this.couriernumber) {
        ElMessage.error('请输入快递单号')
        return
      }

      if (!this.couriername) {
        ElMessage.error('请输入快递公司名字')
        return
      }

      const data = {
        deliverySn: this.couriernumber,
        orderSn: this.orderSn,
        deliveryCompany: this.couriername
      }
      try {
        http.post('mall-merchant/order/sellerShipments', data)
          .then(res => {
            if (res.data.code === 500) {
              ElMessage({
                message: res.data.msg,
                type: 'error'
              })
              return
            } else {
              ElMessage({
                message: '发货成功',
                type: 'success'
              })
              this.courierdisabled = true
              this.fhshow = false
              // this.$set(this.list[this.orderindex], 'status', 6)
              this.dataList[this.orderindex].status = 6
            }
            console.log(res)
          })
      } catch (e) {
        this.courierdisabled = false
        // TODO handle the exception
      }
    },
    // 发货
    confirmfp (orderSn, index, type) {
      // console.log(orderSn)
      // console.log(index)
      // console.log(type)
      this.orderindex = index
      this.orderSn = orderSn
      if (type === 0) {
        this.$confirm('您确定发货吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const data = {
              orderSn: this.orderSn
            }
            http.post('mall-merchant/order/sellerShipments', data)
              .then(res => {
                if (res.data.code === 500) {
                  ElMessage({
                    message: res.data.msg,
                    type: 'error'
                  })
                  return
                } else {
                  ElMessage({
                    message: '发货成功',
                    type: 'success'
                  })
                  this.courierdisabled = true
                  this.fhshow = false
                  // this.$set(this.list[this.orderindex], 'status', 6)
                  this.dataList[this.orderindex].status = 6
                }
                console.log(res)
              })
          })
          .catch(() => {})
      } else {
        this.courierdisabled = false
        this.fhshow = true
      }
    },
    // 卖家确认预提
    saleyt (orderSn, index) {
      console.log(index)
      this.ytIndex = index
      const loading = this.$loading({
        lock: true,
        text: '请稍等...'
      })
      http.get(`/mall-order/order/getOrderInfoByOrderId/${orderSn}`)
        .then(res => {
          this.ytData = res.data.data
          const ytWayValue = res.data.data.omsOrder.preTakeWayId

          for (const i in res.data.data.preTakeWayEntities) {
            if (res.data.data.preTakeWayEntities[i].id === ytWayValue) {
              this.ytWayValue = res.data.data.preTakeWayEntities[i].spec
              this.ytPrice = res.data.data.preTakeWayEntities[i].price
            }
          }
          loading.close()
          this.showYt = true
          console.log(this.ytData)
        }).catch(err => {
          console.log(err)
        })
    },
    // 预提提交？？
    subadvance () {
      if (this.isEnter === 0) {
        if (!this.rejected) {
          ElMessage.error({
            message: '请输入驳回理由'
          })
          return
        }
      }
      this.advancedisabled = true
      const loading = this.$loading({
        lock: true,
        text: '提交中'
      })
      let flag = true
      if (this.isEnter === 1) {
        flag = true
        this.rejected = null
      } else {
        flag = false
      }
      const data = {
        orderSn: this.ytData.omsOrder.orderSn,
        flag: flag,
        note: this.rejected
      }
      try {
        http.post('mall-merchant/order/sellerConfirmPre', data)
          .then(res => {
            if (res.data.code === 200) {
              console.log(res)
              loading.close()
              this.showYt = false
              this.dataList[this.ytIndex].status = 34
              ElMessage.success({
                message: '提交成功'
              })
              // this.initOrderList()
            } else {
              this.advancedisabled = false
              loading.close()
              ElMessage.error(res.data.msg)
            }
          }).catch(err => {
            this.showYt = false
            console.log(err)
            loading.close()
          })
      } catch (e) {
        this.showYt = false
        loading.close()
        // TODO handle the exception
      }
    },
    radioChange (e) { // 切换驳回选项
      this.isEnter = e
    },
    async cancelorder (orderSn, index) {
      console.log(orderSn)
      const data = {
        orderSn: orderSn
      }
      this.$confirm('您确定要取消此订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const res = cancelOrder(data)
          res.then(resj => {
            if (resj.data.code === 200) {
              ElMessage.success({
                message: resj.data.msg
              })
              this.dataList[index].status = 10
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch(() => {})
    },
    handleCurrentChange (val) {
      console.log(`current page: ${val}`)
      this.currentPage = val
      this.clickInputOrderNum()
    }
  },
  mounted () {
  },
  created () {
    this.initOrderList('')
    this.getProductAttribute() // 初始化分类
    this.initdata() // 初始化数量
    this.getLogistics() // 获取物流公司
  },
  setup () {
    const datacop = getCurrentInstance()
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    // const handleCurrentChange = (val) => {
    //   console.log(`current page: ${val}`)
    //   datacop.data.currentPage = val
    //   // initOrderList(datacop.data.statusList)
    // }
    const initOrderList = (status) => {
      // console.log(status)
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等...'
      })
      // 查询条件
      const getData = {
        status: status,
        size: 15,
        page: datacop.data.currentPage
      }
      http.get('/mall-merchant/order/getOrderListByMemberIdAndStatus', getData)
        .then(res => {
          if (status === '') {
            datacop.data.labelStatus[0] = res.data.data.total
          }
          console.log(res)
          datacop.data.totalnum = res.data.data.total
          datacop.data.dataList = res.data.data.records
          loading.close()
        }).catch(err => {
          console.log(err)
        })
    }
    // initOrderList()
    return {
      handleSizeChange,
      initOrderList,
      Search
    }
  }
}
</script>

<style lang="scss" >

.order_man{
  background: #ffffff;
  .order_sear{
    .filter-container{
      .el-card__body{
        .selectname{
          display: inline-block;
          margin-right: 20px;
          width: 400px;
        }
      }
    }
  }
  .order_menu{
    .el-tabs{
      .el-tabs__header{
        .el-tabs__nav-wrap{
          .el-tabs__nav-scroll{
            .el-tabs__nav{
              .el-tabs__active-bar{
                background: #C6824E;
              }
              .is-active{
                color: #C6824E;
              }
              .el-tabs__item:hover{
                color: #c49979;
              }
            }
          }
        }
      }
    }
  }
  .table_msg{
    .order_body{
      tr{
        display: flex;
        th{
          height: 40px;
          line-height: 40px;
          background: #F7F7F7;
          border: 1px solid #EAEAEA;
          text-align: left;
          box-sizing: border-box;
          padding: 0 20px;
          flex: 1;
          white-space: normal;
          overflow: hidden;
          word-break: break-all;
        }
        .content{
          flex: 1;
          display: flex;
          flex-flow: column;
          .order_body_ul{
            flex: 1;
            display: flex;
            .order_body_li{
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 1;
              height: 65px;
              box-sizing: border-box;
              padding: 14px 20px;
              border: 1px solid #EAEAEA;
              .money_span,p{
                color: #333333;
              }
              .exchange{
                color: #C6824E;
              }
            }
            .body_num1{
              width: 200px;
            }
            .body_num7{
              display: flex;
              flex-flow: column;
              span{
                border: 0;
                margin: 0;
                padding: 0;
                color: #C6824E;
                font-size: 14px;
                background: transparent;
                margin-bottom: 10px;
                cursor: pointer;
              }
              .order_detailcss{
                color: #C6824E;
              }
            }
          }
          .nodata{
            width: 100%;
            // margin: 0 auto;
            min-height: 800px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              margin: 0 auto;
              height: 300px;
            }
          }
        }
      }
    }
    .el-overlay{
      .el-overlay-dialog{
        .el-dialog{
          .el-dialog__header{
            text-align: center;
            font-weight: bold;
            .el-dialog__title{
              color: #C6824E;
            }
          }
          .el-dialog__body{
            padding-left: 50px;
            padding-right: 50px;
            .pay{
              .el-form{
                .el-form-item{
                  .el-form-item__content{
                    .el-select{
                      width: 100%;
                    }
                    .from_geren{
                      .show_img_ul{
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .show_img_li{
                          // width: 33%;
                          display: flex;
                          flex-flow: column;
                          align-items: center;
                          // margin-bottom: 30px;
                          img{
                            width: 187px;
                            height: 127px;
                          }
                          span{
                            font-size: 14px;
                            color: #333333;
                          }
                          div{
                            width: 187px;
                            height: 127px;
                            .el-upload--picture-card{
                              background: transparent;
                              border: none;
                              width: 100% !important;
                              height: 100% !important;
                            }
                            .el-upload-list{
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .el-button{
                margin: 0 auto;
                width: 120px;
              }
            }
            .enterytmsg{
              // 确认预提信息
              .textItem{
                margin-bottom: 17px;
                span{
                  font-size: 14px;
                  color: #999999;
                }
                .valueTxt{
                  color: #333333;
                }
              }
              .sfenter{
                margin: 0;
              }
              .footbtn{
                display: flex;
                justify-content: center;
                .el-button{
                  width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
  .showfooter{
    height: 100px;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
