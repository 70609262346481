<template>
<!-- 竞拍管理 -->
<div class='auctcont'>
  <div class="title">
    竞拍管理
  </div>
  <el-card class="filter-container" shadow="never">
    <template #header>
      <span>筛选</span>
    </template>
    <div class="selectname">
      <el-radio-group v-model="radio" size="small" @change="currentPage=1;initData(1)">
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button label="0">待活动开始</el-radio-button>
        <el-radio-button label="1">活动中</el-radio-button>
        <el-radio-button label="2">抢拍结束</el-radio-button>
      </el-radio-group>
    </div>
    <!-- <div class="selectname">
      <el-select v-model="fenleiValue" filterable clearable placeholder="分类选择" size="small"
      @change="fenleichange">
        <el-option
          v-for="item in fenleioptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </div> -->
  </el-card>

  <div class="order_body">
    <el-table id="out-table" ref="productTable" :data="dataList" style="width: 100%" border>
      <el-table-column label="拍卖标题" width="200" align="center">
        <template #default="scope">{{scope.row.name}}</template>
      </el-table-column>
      <el-table-column label="商品名称" width="" align="center">
        <template #default="scope">{{scope.row.productName}}</template>
      </el-table-column>
      <el-table-column label="数量 / 单位" width="" align="center">
        <template #default="scope">{{scope.row.smsAuctionSku.stock}}
          <el-tag size="small" type="success">{{scope.row.unit}}</el-tag></template>
      </el-table-column>
      <el-table-column label="商家名称" width="" align="center">
        <template #default="scope">{{scope.row.umsCompanyInfoEntity.companyName}}</template>
      </el-table-column>
      <el-table-column label="开始时间" width="" align="center">
        <template #default="scope">{{scope.row.startTime}}</template>
      </el-table-column>
      <el-table-column label="结束时间" width="" align="center">
        <template #default="scope">{{scope.row.endTime}}</template>
      </el-table-column>
      <el-table-column label="审核状态" width="" align="center">
        <template #default="scope">{{
          scope.row.status==0?'待活动开始':
          scope.row.status==1?'活动中':
          scope.row.status==2?'未成交':
          scope.row.status==3?'已成交':
          scope.row.status==4?'待审核':
          scope.row.status==5?'审核未通过':
          '未知状态'}}
          <el-tag v-if="scope.row.auditStatus==2" size="small" type="warning">{{scope.row.reviewComments}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column label="审核意见" width="" align="center">
        <template #default="scope">{{scope.row.remark}}</template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template #default="scope">
          <el-button type="text" size="small" @click="openDetails(scope.row.id, scope.row)">查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
   <!-- 底部分页 -->
  <div class="showfooter" v-if="totalnum<pagesize?false:true">
    <el-pagination
      v-model:currentPage="currentPage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalnum"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
  <!-- 查看详情 -->
  <div>
    <el-dialog
      :title="'查看详情'"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div>
        <el-form :model="auditStatusQuery"
             ref="countryIntroFrom"
             label-width="150px"
             label-suffix="："
             size="small">
            <el-form-item label="拍卖标题">
              {{auditStatusQuery.name}}
            </el-form-item>
            <el-form-item label="商品名称">
              {{auditStatusQuery.productName}}
            </el-form-item>
            <el-form-item label="商品图片">
              <el-image
              style="max-height: 100px; max-width: 200px"
              :src="auditStatusQuery.productPic"></el-image>
            </el-form-item>
            <el-form-item label="商家名称">
              {{auditStatusQuery.umsCompanyInfoEntity.companyName}}
            </el-form-item>
            <el-form-item label="数量 / 单位">
              {{auditStatusQuery.smsAuctionSku.stock}}
              <el-tag size="small" type="success">{{auditStatusQuery.unit}}</el-tag>
            </el-form-item>
            <el-form-item label="定金">
              {{auditStatusQuery.percent}}
            </el-form-item>
            <el-form-item label="最高价">
              {{auditStatusQuery.smsAuctionSku.realPrice}}
            </el-form-item>
            <el-form-item label="起拍价">
              {{auditStatusQuery.smsAuctionSku.startPrice}}
            </el-form-item>
            <el-form-item label="起加价">
              {{auditStatusQuery.smsAuctionSku.minqj}}
            </el-form-item>
            <el-form-item label="规格">
              {{auditStatusQuery.smsAuctionSku.spData[1].value}}
            </el-form-item>
            <el-form-item label="创建时间">
              {{auditStatusQuery.createTime}}
            </el-form-item>
            <el-form-item label="开始时间">
              {{auditStatusQuery.startTime}}
            </el-form-item>
            <el-form-item label="结束时间">
              {{auditStatusQuery.endTime}}
            </el-form-item>
            <el-form-item label="竞拍记录">
              <el-table size='small' border
                :data="modTable.cont"
                style="width: 100%">
                <el-table-column
                  prop="price"
                  label="价格"
                  width="180">
                  <template v-slot="scope">
                    {{scope.row.price}}
                    <el-tag v-if="scope.$index == 0" type="success" size="mini">{{'最高价'}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="username"
                  label="竞拍人"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="时间">
                </el-table-column>
                <el-table-column
                  prop="deposit"
                  label="定金">
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="审核状态">
                {{
                  auditStatusQuery.status==0?'待活动开始':
                  auditStatusQuery.status==1?'活动中':
                  auditStatusQuery.status==2?'未成交':
                  auditStatusQuery.status==3?'已成交':
                  auditStatusQuery.status==4?'待审核':
                  auditStatusQuery.status==5?'审核未通过':
                  '未知状态'}}
            </el-form-item>
            <el-form-item label="审核意见">
              {{auditStatusQuery.remark}}
            </el-form-item>
          </el-form>
      </div>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../../api/http'
import { ElLoading } from 'element-plus'

// 管理商品全部查询
const getAuctionList = (data) => {
  return http.get('/mall-merchant/auction/getAuctionList', data)
}
// 获取抢拍活动的参与者记录
const merchantGetRushHistory = (data) => {
  return http.get('/mall-merchant/auction/merchantGetRushHistory', data)
}

// 查分类
const productAttribute = (data) => {
  return http.get('/mall-portal/productCategory/list/withChildren')
}
export default {
  name: 'auctionMain',
  data () {
    return {
      dataList: [],
      modTable: { // 出价记录list
        title: ['价格', '竞拍人', '时间'],
        cont: [
        ]
      },
      auditStatusQuery: {},
      dialogVisible: false,
      fenleioptions: [], // 分类
      fenleiValue: '', // 选中的分类id

      // 上下架商品
      radio: '', // 0：待活动开始，1：活动中，2：抢拍结束

      // 底部分页
      totalnum: ref(100), // 存放总数据个数
      pagesize: ref(15), // 存放一页显示个数
      currentPage: ref(1) // 默认展示第几页
    }
  },
  components: {},
  methods: {
    async initData (num) { // 初始化查询商品
      const loading = ElLoading.service({
        lock: true,
        text: '查询中...'
      })
      const data = {
        pageSize: this.pagesize,
        pageNum: num,
        status: this.radio
      }
      console.log(data)
      const res = await getAuctionList(data)
      if (res.data.code === 200) {
        // console.log(res)
        for (const item of res.data.data.records) {
          item.smsAuctionSku.spData = JSON.parse(item.smsAuctionSku.spData)
        }
        this.totalnum = res.data.data.total // 总数据数
        this.dataList = res.data.data.records // 要展示的数据
        console.log(this.dataList)
        loading.close()
      }
      loading.close()
    },
    handleCurrentChange (val) { // 分页
      console.log(`current page: ${val}`)
      this.initData(val)
    },
    // 初始化分类列表
    async getProductAttribute () {
      const res = await productAttribute()
      // console.log(res)
      if (res.data.code === 200) {
        res.data.data[0].children.forEach(item => {
          this.fenleioptions.push({
            name: item.name,
            id: item.id
          })
        })
      }
    },
    openDetails (id, data) { // 查看拍卖详情
      // console.log(JSON.parse(JSON.stringify(data)));
      merchantGetRushHistory({ // 查询竞拍记录
        pageNum: 1,
        pageSize: 1000,
        auctionId: id
      }).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          if (!res.data.data) {
            this.modTable.cont = []
          } else {
            this.modTable.cont = res.data.data.records
          }
          this.auditStatusQuery = Object.assign({}, data)
          // console.log(JSON.parse(JSON.stringify(this.auditStatusQuery)))
          this.dialogVisible = true
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleClose (done) {
      this.dialogVisible = false
    },
    fenleichange () {
      this.initData(1)
    }
  },
  mounted () {
    this.initData(1)
    this.getProductAttribute()
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    return {
      handleSizeChange
    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.auctcont{
  padding: 20px;
  .title{
    height: 50px;
  }
  .filter-container{
    .el-card__body{
      .selectname{
        display: inline-block;
        margin-right: 20px;
        width: 300px;
      }
    }
  }
  .order_body{
    width: 100%;
  }
  .showfooter{
    height: 100px;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
