<!-- eslint-disable vue/no-mutating-props -->
<template>
<div class="selectclass_div" style="margin-top: 50px">
  <el-form :model="value" :rules="rules" ref="productInfoForm" size="small" label-width="120px">
    <el-form-item label="商品分类：" required>
      <el-cascader size="small"
        v-model="selectProductCateValue"
        :options="productCateOptions">
      </el-cascader>
    </el-form-item>
    <el-form-item :label="$t('m.index.product_name')+'：'" prop="name"> <!--商品名称-->
      <el-input v-model="value.name" size="small"></el-input>
    </el-form-item>
    <el-form-item :label="$t('m.reservation_varietiy_details.brand')+'：'" prop="brandName"> <!--品牌-->
      <el-input  maxlength="10" v-model="value.brandName" size="small"></el-input>
    </el-form-item>
    <el-form-item :label="$t('m.index.price')+'：'" prop="price"> <!--价格-->
          <el-input maxlength="8"
          v-model="value.price"
          @change="confirmblAdbill('jg')"
          size="small"></el-input>
    </el-form-item>
    <el-form-item :label="$t('m.merchantworkbench.producer')+'：'" prop="placeOfOrigin"> <!--产地-->
      <el-input  maxlength="10" v-model="value.placeOfOrigin" size="small"></el-input>
    </el-form-item>
    <el-form-item style="text-align: center;margin-top: 30px" label-width="0px">
      <el-button size="small" @click="handleNext('productInfoForm')">下一步，填写商品信息</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import http from '../../../../api/http'
// 查询所有一级分类及子分类
const fetchListWithChildren = (data) => {
  return http.get('/mall-merchant/productCategory/list/withChildren')
}
export default {
  name: 'selectProductClass',
  data () {
    return {
      selectProductCateValue: [],
      productCateOptions: [],
      rules: {
        selectProductCateValue: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        subject_value: [{ required: true, message: '请选择品种', trigger: 'blur' }],
        brandName: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
        price: [{ required: true, message: '请输入正确的价格', trigger: 'blur' }],
        placeOfOrigin: [{ required: true, message: '请输入产地', trigger: 'blur' }]
      }
    }
  },
  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  watch: {
    selectProductCateValue: function (newValue) {
      // console.log(newValue)
      // console.log(this.$props)
      if (newValue != null) {
        this.$props.value.productCategoryId = newValue[newValue.length - 1 > 0 ? newValue.length - 1 : 0]
        this.$props.value.productCategoryName = this.getCateNameById(this.$props.value.productCategoryId)
      } else {
        this.$props.value.productCategoryId = null
        this.$props.value.productCategoryName = null
      }
    }
  },
  methods: {
    confirmblAdbill (str) { // 输入内容时正则判断数据
      switch (str) {
        case 'jg':
          this.$props.value.price = Number((parseInt(this.$props.value.price * 100) / 100).toFixed(2))
          // /(^[1-9]\d*\.?\d*)|(0\.\d*[1-9]$)/
          if (!/(^[1-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.$props.value.price)) {
            this.$props.value.price = ''
          }
          break
        default:
          break
      }
    },
    getProductCateList () {
      fetchListWithChildren().then(response => {
        console.log(response)
        if (response.data.code === 200) {
          const list = response.data.data
          this.productCateOptions = []
          for (let i = 0; i < list.length; i++) {
            const children = []
            if (list[i].children != null && list[i].children.length > 0) {
              for (let j = 0; j < list[i].children.length; j++) {
                children.push({ label: list[i].children[j].name, value: list[i].children[j].id })
              }
            }
            this.productCateOptions.push({ label: list[i].name, value: list[i].id, children: children })
          }
        }
      })
    },
    getCateNameById (id) {
      let name = null
      for (let i = 0; i < this.productCateOptions.length; i++) {
        for (let j = 0; j < this.productCateOptions[i].children.length; j++) {
          if (this.productCateOptions[i].children[j].value === id) {
            name = this.productCateOptions[i].children[j].label
            return name
          }
        }
      }
      return name
    },
    handleNext (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('nextStep')
        } else {
          ElMessage.error({
            message: '请完善商品基本分类',
            duration: 1000
          })
          return false
        }
      })
    }
  },
  mounted () {
    this.getProductCateList()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.selectclass_div{
  .el-form{
    .el-form-item{
      .el-form-item__label{
        min-width: 100px;
      }
      .el-form-item__content{
        .el-input{
          width: 500px;
        }
      }
    }
  }
}

</style>
